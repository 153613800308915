const familyMedicalHistoryNames = [
    "familyInsulinResistance",
    "familyType2Diabetes",
    "familyHighBloodPressure",
    "familyDyslipidemia",
    "familyCardiovascularDisease",
    "familyStroke",
    "familyArthritis",
    "familySleepApnea",
    "familyGallbladderDisease",
    "familyHyperuricemia",
    "familyOsteoarthritis",
    "familyCancer",
    "familyDepression",
    "familyOtherDiseases"
    // "fa-insulinResistance",
    // "fa-type2Diabetes",
    // "fa-highBloodPressure",
    // "fa-dyslipidemia",
    // "fa-cardiovascularDisease",
    // "fa-stroke",
    // "fa-arthritis",
    // "fa-sleepApnea",
    // "fa-gallbladderDisease",
    // "fa-hyperuricemia",
    // "fa-osteoarthritis",
    // "fa-certainCancers",
    // "fa-depression",
    // "fa-other"
];

export {familyMedicalHistoryNames};