const aboutMeAlcoholModelName = "alcohol";
const aboutMeAgeModelName = "age";
const aboutMeGenderModelName = "gender";
const aboutMeSmokingStatusModelName = "smoking_status";
const aboutMeSmokingFrequencyModelName = "smoking_frequency"; 
const aboutMeSmokingDurationModelName = "smoking_duration"; 
const dietCalorieRestrictionModelName = "calorie_restriction";
const dietFatTransModelName = "fat_trans";
const dietGrainRefinedModelName = "grain_refined";
const dietGrainUnrefinedModelName = "grain_unrefined";
const dietLegumesModelName = "legumes";
const dietMeatProcessedModelName = "meat_processed";
const dietMeatUnprocessedModelName = "meat_unprocessed";
const dietMeatPoultryModelName = "meat_poultry";
const dietFruitsAndVeggiesModelName = "fruits_and_veggies"; 
const dietWaterModelName = "water";
const dietRefinedSugarModelName = "refined_sugar";
const dietArtificialSweetenerModelName = "artificial_sweetener";
const exerciseCardioModelName = "cardio";
const exerciseStrengthTrainingModelName = "strength_training";
const mySleepSleepDurationModelName = "sleep_duration";
const mySleepSleepQualityModelName = "sleep_quality_score_lifestyle";
const exerciseSaunaFrequencyModelName = "sauna_frequency";
const myStressStressQualityModelName = "stress_level_score";
const nodeStressQualityModelName = "stress_level_score";
const supplementsCalciumModelName = "calcium";
const supplementsFishOilOmega3ModelName = "fish_oil_omega_3";
const supplementsGreenTeaModelName = "green_tea";
const smoking_frequencyModelName = "smokingfrequencyLable";
export {
    aboutMeAlcoholModelName, aboutMeAgeModelName, aboutMeGenderModelName, aboutMeSmokingStatusModelName,
    aboutMeSmokingFrequencyModelName, dietCalorieRestrictionModelName, dietFatTransModelName,
    dietGrainRefinedModelName, dietGrainUnrefinedModelName, dietLegumesModelName, dietMeatProcessedModelName,
    dietMeatUnprocessedModelName, dietMeatPoultryModelName, dietFruitsAndVeggiesModelName, dietWaterModelName,
    dietRefinedSugarModelName, dietArtificialSweetenerModelName, exerciseCardioModelName, exerciseStrengthTrainingModelName,
    mySleepSleepDurationModelName, mySleepSleepQualityModelName, exerciseSaunaFrequencyModelName, nodeStressQualityModelName,aboutMeSmokingDurationModelName,
    supplementsCalciumModelName, supplementsFishOilOmega3ModelName, supplementsGreenTeaModelName, myStressStressQualityModelName, smoking_frequencyModelName
};
