import './TermsAndConditions.css';
import React, { useRef, useEffect } from 'react';
import companyLogo from '../../components/images/companyLogo.jpg';

function TermsAndConditions({ setHasScrolledToBottom }) {
  const endOfTermsRef = useRef(null);
  const scrollListener = () => {
    if (!endOfTermsRef.current) return;
  
    const { scrollTop, scrollHeight, clientHeight } = endOfTermsRef.current;
    if (scrollHeight - scrollTop <= clientHeight + 5) {
      setHasScrolledToBottom(true);
    } else {
      setHasScrolledToBottom(false);
    }
  };
  
  useEffect(() => {
    const termsElement = endOfTermsRef.current;
    if (termsElement) {
      termsElement.addEventListener('scroll', scrollListener);
    }
  
    return () => {
      if (termsElement) {
        termsElement.removeEventListener('scroll', scrollListener);
      }
    };
  }, []);
  return (
    <div className="terms-conditions-container" ref={endOfTermsRef} style={{ maxHeight: '600px', overflowY: 'auto' }}>
      <div className="company-logo-container">
        <img src={companyLogo} alt="Company Logo" className="company-logo" />
      </div>
      <h2>Disclaimer</h2>
      <p>
      The information provided on this website is intended for informational purposes only. It is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of a qualified healthcare provider with any questions you may have regarding a medical condition.
        This website is not designed to and does not provide medical advice, professional diagnosis, opinion, treatment, or services to you or to any other individual. The content on this site is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified healthcare provider with any questions you may have regarding a medical condition.
        You should never disregard professional medical advice or delay seeking medical treatment because of something you have read on this website. If you think you may have a medical emergency, call your doctor or emergency services immediately.
        This website does not recommend or endorse any specific tests, physicians, products, procedures, opinions, or other information that may be mentioned on the site. Reliance on any information provided by this website is solely at your own risk.
        By accessing and using this website, you acknowledge that you have read and understood this disclaimer and agree to be bound by its terms. If you do not agree to these terms, you should not use this website.

      </p>
      <h2>Terms of Service</h2>
      <p>
      These Terms of Service ("Terms") govern your access to and use of [My Youthspan] (the "Service"), operated by [METY Technology, Inc.] ("Company"). By accessing or using the Service, you agree to be bound by these Terms.
      </p>
      <h3>1. Subscription</h3>
      <p>1.1. Subscription Plans: The Service offers various subscription plans ("Plans") with different features and pricing. By subscribing to a Plan, you agree to pay the applicable fees and abide by the terms of that Plan.</p>
      <p>1.2. Payment: You authorize the Company to charge your chosen payment method for the recurring subscription fees. You agree to provide accurate and complete payment information and to keep it up to date.</p>
      <p>1.3. Renewal: Your subscription will automatically renew at the end of each billing cycle unless you cancel it before the renewal date. The renewal fees will be charged to your payment method.</p>
      
      <h3>2. Use of the Service</h3>
      <p>2.1. Access: Subject to these Terms, the Company grants you a non-exclusive, non-transferable, revocable license to access and use the Service for your personal or internal business purposes.</p>
      <p>2.2.Prohibited Activities: You agree not to engage in any of the following activities:•	Violating any applicable laws or regulations.•	Interfering with or disrupting the Service or servers.•	Reverse engineering, decompiling, or modifying the Service.•	Transmitting any viruses or malicious code.•	Unauthorized access to other users' accounts.</p>
        <p>2.3. Content: The Service may contain user-generated content, third-party links, and other materials ("Content"). The Company does not endorse, control, or guarantee the accuracy or quality of such Content.</p>

      <h3>3. Intellectual Property</h3>
      <p>3.1. Ownership: All intellectual property rights in the Service and Content are owned by the Company or its licensors. You may not use or reproduce them without prior written permission.</p>

        <h3>4. Privacy Policy</h3>
        <p>4.1. Privacy: Your use of the Service is subject to the Company's Privacy Policy, which governs the collection, use, and disclosure of your personal information.</p>
      <h3>5. Termination</h3>
      <p>5.1. Termination: The Company reserves the right to suspend or terminate your access to the Service at any time for violation of these Terms or any other reason.</p>
      <h3>6. Limitation of Liability</h3>
      <p>6.1. Disclaimer: The Service is provided on an "as is" and "as available" basis without warranties of any kind. The Company shall not be liable for any indirect, incidental, special, or consequential damages.</p>
        <h3>7. Miscellaneous</h3>
        <p>7.1. Governing Law: These Terms are governed by the laws of Delaware. Any dispute arising out of or relating to these Terms shall be resolved in the courts of Delaware.</p>
        <p>7.2. Changes: The Company reserves the right to modify or update these Terms at any time. Your continued use of the Service after any changes constitutes acceptance of the modified Terms.
By accessing or using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms.
If you have any questions or concerns about these Terms, please contact us at [info@myyouthspan.com].
</p>
      
      <h2>Privacy Policy</h2>
      <p>
      [Mety Technology] ("Company," "we," "us," or "our") is committed to protecting the privacy of its users. This Privacy Policy explains how we collect, use, disclose, and protect personal information collected through our subscription software service (the "Service").
      </p>
      <h3>1. Information We Collect</h3>
      <p>1.1. Personal Information: When you sign up for our Service, we may collect personal information such as your name, email address, billing information, and other contact details.</p>
    <p>1.2. Usage Information: We may collect information about your use of the Service, including login times, IP addresses, device information, and browser type.</p>
      <h3>2. Use of Information</h3>
      <p>2.1. Provide and Improve the Service: We use the information collected to provide, maintain, and improve the Service, including personalizing your experience and troubleshooting issues.</p>
      <p>2.2. Communications: We may use your contact information to send you service-related communications, such as account updates, invoices, and technical notices.</p>
      <p>2.3. Marketing: With your consent, we may send you promotional emails about new features, products, or special offers. You can opt-out of these communications at any time.</p>
      <h3>3. Data Sharing and Disclosure</h3>
      <p>3.1. Third-Party Service Providers: We may share personal information with third-party service providers who assist us in providing the Service, such as hosting providers, payment processors, and customer support services.</p>
      <p>3.2. Legal Compliance: We may disclose personal information if required by law or in response to valid legal requests, such as subpoenas or court orders.</p>
      <h3>4. Data Security</h3>
      <p>4.1. Security Measures: We implement reasonable security measures to protect the confidentiality and integrity of your personal information, including encryption and access controls.</p>
      <p>4.2. Data Breach Notification: In the event of a data breach that compromises your personal information, we will notify you and relevant authorities as required by law.</p>
      <h3>5. Data Retention</h3>
      <p>5.1. Retention Period: We retain personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
      <h3>6. Your Rights</h3>
      <p>6.1. Access and Correction: You have the right to access and correct your personal information stored in our systems. You can update your account information or request changes by contacting us.</p>
      <p>6.2. Data Deletion: You can request the deletion of your personal information from our systems. However, please note that we may retain certain information as required by law or for legitimate business purposes.</p>
      <h3>7. Children's Privacy</h3>
      <p>7.1. Age Restriction: The Service is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe that we have inadvertently collected information from a child under 13, please contact us immediately.</p>
      <h3>8. Changes to this Policy</h3>
      <p>8.1. Policy Updates: We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our website or sending you a notification.</p>
      <h3>9. Contact Us</h3>
      <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at [info@myyouthspan.com].</p>
    
      <div ref={endOfTermsRef} />
    </div>
  );
}

export default TermsAndConditions;
