import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import ProfileResponseDetail from "../components/ProfileResponseDetail";
import { useNavigate } from "react-router-dom";
import  "./InterventionSelection.css";
import { Container } from "react-bootstrap";
// import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";


function InterventionSelection ( { reportData, setReportData }) {
  // later, load a similar array from backend after Profile form completion
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const recalculateInterventions = () => {
    console.log("recalculate interventions called");
    console.log("current reportData state: ", reportData);
  };

  const cancel = () => {
    console.log("cancel called");
  };
  let interventionList = {
    "Sleep at a defined time every night.": false,
    "No exposure to blue light an hour prior to bed.": true,
    "Get enough stimulus during the day.":false,
    "Wake up at the same time every day including weekends and vacations.":false,
    "Avoid alcohol before bed.":false
  }
  const [checkboxItem, setCheckedboxItem] = React.useState(interventionList);

  const handleCIChange=(e) => {
    // const updatedCheckboxItem = checkboxItem.map((item) =>
    //   item === p ? !item : item
    // );
    // console.log(updatedCheckboxItem);
    // setCheckedboxItem(updatedCheckboxItem);
    setCheckedboxItem({ ...checkboxItem, [e.target.name]: !checkboxItem[e.target.name] });
    console.log(checkboxItem);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/longevity-questionnaire-response")
  };


  return (
    <div style={{ backgroundColor: "#F5F5F5", height:"100%"}}>
      {/* { reportData && console.log("reportData values: ", Object.values(reportData))} */}
      <div className="intervention_sug_box">
        <div className="intervention_sug_txt">
          <p> Pick from the list of what you’re willing to do</p>
       
        </div>
      </div>
      {/* <Container> */}
      <Form onSubmit={handleSubmit}>
      <div className="intervention_box">
        <div className="intervention_title">
          Sleep Interventions
        </div>

        {/* {Object.keys(checkboxItem).map((item) => (
          <div className="intervention_item_box">
            <div key={item}>{item}</div>
            <div>
              <input type="checkbox" checked={checkboxItem[item] } onChange={()=>handleCIChange(item)}/>
            </div>
          </div>
          
         ))} */}

        <Form.Group>
                {Object.keys(checkboxItem).map((item) => (
                  <div className="intervention_item_box">
                    <div key={item} className="intervention_item_content">{item}</div>
                    <Form.Check
                      checked={checkboxItem[item]}
                      id={item}
                      name={item}
                      onChange={handleCIChange}
                    />
                  </div>
                ))}
              </Form.Group>
         
        <Form.Group>
            <div className="inlineContainer" style={{ marginTop: "60px" }}>
              <Button
                type="submit"
                variant="dark"
                style={{ minWidth: "225px", height: "45px" }}
              >
                Submit
              </Button>
            </div>
          </Form.Group>

        {/* <div className="intervention_item_box">
          <div>Sleep at a defined time every night.</div>
          <div>
            <input type="checkbox" checked={checkedOne} />
          </div>
        </div> */}
      </div>
      </Form>
      {/* </Container> */}
      
    </div>
  );
}

export default InterventionSelection;
