import { createContext, useReducer, useEffect, useMemo } from 'react'

export const AuthContext = createContext()

export const authReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            return { user: action.payload }
        case "LOGOUT":
            localStorage.removeItem("mysUser")
            return { user: null }
        default:
            return state
    }
}

export const AuthContextProvider = ({ children }) => {

    const [state, dispatch] = useReducer(authReducer, {
        user: JSON.parse(localStorage.getItem("mysUser")) || null
    })

    useEffect(() => {
        if (state.user) {
            localStorage.setItem("mysUser", JSON.stringify(state.user))
        } else {
            localStorage.removeItem("mysUser")
        }
        // const user = JSON.parse(localStorage.getItem("mysUser"))
        // if (user) {
        //     dispatch({ type: "LOGIN", payload: user })
        // }
        // with an empty dependency array, runs only on first render
    }, [state.user])

    console.log("AuthContext state: ", state)
    const contextValue = useMemo(() => ({ ...state, dispatch }), [state])
    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    )
}