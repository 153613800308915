const bedtimeLabel = "During the past month what time have you usually gone to bed at night?";
const sleepOnsetLabel = "During the past month, how long (in minutes) has it usually taken you to fall asleep each night?";
const wakeUpTimeLabel = "During the past month what time have you usually gotten up in the morning?";
const actualSleepHoursLabel = "During the past month, how many hours of actual sleep did you get at night? (This may be different than the number of hours you spend in bed.)";

const sleepQualityLabel = "During the past month, how would you rate your sleep quality overall?";
const sleepMedicationLabel = "During the past month, how often have you taken medicine (prescribed or “over the counter”) to help you sleep?";
const stayAwakeTroubleLabel = "During the past month, how often have you had trouble staying awake while driving, eating meals, or engaging in social activity?";
const enthusiasmLevelLabel = "During the past month, how much of a problem has it been for you to keep up enough enthusiasm to get things done?";

const fallAsleepDifficultyLabel = "Cannot fall asleep within 30 minutes";
const wakeUpMidnightLabel = "Wake up in the middle of the night";
const bathroomNeedsLabel = "Have to go to the bathroom";
const breathingDifficultyLabel = "Cannot breathe comfortably";
const loudSnoringLabel = "Snore loudly";
const coldFeetLabel = "Feet too cold";
const hotFeetLabel = "Feet too hot";
const badDreamsLabel = "Have bad dreams";
const painLabel = "Have pain";

export {
    bedtimeLabel, sleepOnsetLabel, wakeUpTimeLabel, actualSleepHoursLabel,
    sleepQualityLabel, sleepMedicationLabel, stayAwakeTroubleLabel, enthusiasmLevelLabel,
    fallAsleepDifficultyLabel, wakeUpMidnightLabel, bathroomNeedsLabel, breathingDifficultyLabel,
    loudSnoringLabel, coldFeetLabel, hotFeetLabel, badDreamsLabel, painLabel
};
