import React, {useMemo, useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {Button, Form, OverlayTrigger, Tooltip, Modal} from 'react-bootstrap'
import Nav from "react-bootstrap/Nav"
import './Progress.css'
import { useAuthContext } from '../../hooks/useAuthContext'
import { setCookieValue, getCookie } from "../../utils/cookie"
import { fetchData, submitData, fetchScores, fetchUserData } from './action'
import { getLifestyleResponseData } from '../FullProfile/actions'


function Progress() {

    const {user} = useAuthContext()
    let navigate = useNavigate()

    const firstName = useMemo(() => {
        let user = localStorage.getItem("mysUser");
          user = user
            ? JSON.parse(user)
            : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
        return user.firstName;
    })

    const userId = useMemo(() => {
        let user = localStorage.getItem("mysUser");
          user = user
            ? JSON.parse(user)
            : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
        return user.userId;
    })

    //Answer Fields
    const [walkspeed, setWalkSpeed] = useState("")
    const [grip, setGrip] = useState("")
    const [weight, setWeight] = useState("")
    const [systolic, setSystolic] = useState("")
    const [diastolic, setDiastolic] = useState("")
    const [chototal, setChototal] = useState("")
    const [choldl, setCholdl] = useState("")
    const [chohdl, setChohdl] = useState("")

    const [formData, setFormData] = useState()

    const [testScores, setTestScores] = useState()
    const [pitt, setPitt] = useState("NA")
    const [pittDate, setPittDate] = useState("NA")
    const [stress, setStress] = useState("NA")
    const [stressDate, setStressDate] = useState("NA")
    const [digit, setDigit] = useState("NA")
    const [digitDate, setDigitDate] = useState("NA")
    const [reactiontime, setReactiontime] = useState("NA")
    const [reactiontimeDate, setReactiontimeDate] = useState("NA")
    const [selfreaction, setSelfreaction] = useState("NA")
    const [selfreactionDate, setSelfreactionDate] = useState("NA")
    const [happiness, setHappiness] = useState("NA")
    const [happinessDate, setHappinessDate] = useState("NA")
    const [qol, setQol] = useState("NA")
    const [qolDate, setQolDate] = useState("NA")
    const [appearance, setAppearance] = useState("NA")
    const [appearanceDate, setAppearanceDate] = useState("NA")
    const [sex, setSex] = useState("NA")
    const [sexDate, setSexDate] = useState("NA")
    const [brainfog, setBrainfog] = useState("NA")
    const [brainfogDate, setBrainfogDate] = useState("NA")
    const [sf36item, setSf36item] = useState("NA")
    const [sf36itemDate, setSf36itemDate] = useState("NA")

    const [weightUnit, setWeightUnit] = useState("NA")
    const [walkUnit, setWalkUnit] = useState("NA")

    const [walkLimit, setWalkLimit] = useState(4.47)
    const [gripLimit, setGripLimit] = useState(100)
    const [weightLimit, setWeightLimit] = useState(453.59)
    const [weightMin, setWeightMin] = useState(1);

    const [submittedToday, setSubmittedToday] = useState(true)
    const [firstSubmit, setFirstSubmit] = useState(false);


    useEffect(() => {
        const fetchUData = async (userId) => {
          try {
            const data = await fetchUserData(userId);
            const genderData = await getLifestyleResponseData(userId);
            if (data.preferred_unit_system === "metric") {
                setWeightUnit("kg")
                setWalkUnit("m/s")
                setWalkLimit(4.47)
                setWeightLimit(453.59)
                setWeightMin(0.45)
                if(genderData.data.gender === 0){
                    setGripLimit(68);
                }
                else{
                    setGripLimit(39.9);
                }
            } else {
                setWeightUnit("lbs")
                setWalkUnit("ft/s")
                setWalkLimit(14.67)
                setWeightLimit(1000)
                setWeightMin(1)
                if(genderData.data.gender === 0){
                    setGripLimit(150);
                }
                else{
                    setGripLimit(88);
                }
            }
          } catch (error) {
            console.error('Error fetching user data:', error);
          }
        };
        fetchUData(userId);
    }, []);

    useEffect(() => {
        console.log("data fetched")
        const fetchFormData = async (userId) => {
          try {
            const res = await fetchData(userId);

            if (res.status === 201) {
                
                setFormData(res.data);

                //check if submitted today
                const date = new Date().toUTCString().substring(5, 7)
                if (date === res.data.submission_date.substring(8,10)) {
                    console.log("submitted Today")
                    setSubmittedToday(true)
                } else {
                    setSubmittedToday(false)
                }

            } else if (res.status === 204) {
                setFormData(null)
                setSubmittedToday(false)
            }

          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        fetchFormData(userId);
    }, []);
    
    useEffect(() => {
        const fetchTestScores = async (userId) => {
            try {
                const data = await fetchScores(userId)
                setTestScores(data)
                
                if (data.filter(data => data.testId === 'pitt').length > 0) {
                    setPitt(data.filter(data => data.testId === 'pitt')[0].score)
                    setPittDate(data.filter(data => data.testId === 'pitt')[0].submission_date.substring(0,10))
                }

                if (data.filter(data => data.testId === 'stress').length > 0) {
                    setStress(data.filter(data => data.testId === 'stress')[0].score)
                    setStressDate(data.filter(data => data.testId === 'stress')[0].submission_date.substring(0,10))
                }
                
                if (data.filter(data => data.testId === 'digit').length > 0) {
                    setDigit(data.filter(data => data.testId === 'digit')[0].score)
                    setDigitDate(data.filter(data => data.testId === 'digit')[0].submission_date.substring(0,10))
                }
                
                if (data.filter(data => data.testId === 'reactiontime').length > 0) {
                    setReactiontime(data.filter(data => data.testId === 'reactiontime')[0].score)
                    setReactiontimeDate(data.filter(data => data.testId === 'reactiontime')[0].submission_date.substring(0,10))
                }
                
                if (data.filter(data => data.testId === 'selfreaction').length > 0) {
                    setSelfreaction(data.filter(data => data.testId === 'selfreaction')[0].score)
                    setSelfreactionDate(data.filter(data => data.testId === 'selfreaction')[0].submission_date.substring(0,10))
                }
                
                if (data.filter(data => data.testId === 'happiness').length > 0) {
                    setHappiness(data.filter(data => data.testId === 'happiness')[0].score)
                    setHappinessDate(data.filter(data => data.testId === 'happiness')[0].submission_date.substring(0,10))
                }
                

                if (data.filter(data => data.testId === 'qol').length > 0) {
                    setQol(data.filter(data => data.testId === 'qol')[0].score)
                    setQolDate(data.filter(data => data.testId === 'qol')[0].submission_date.substring(0,10))
                }
                

                if (data.filter(data => data.testId === 'appearance').length > 0) {
                    setAppearance(data.filter(data => data.testId === 'appearance')[0].score)
                    setAppearanceDate(data.filter(data => data.testId === 'appearance')[0].submission_date.substring(0,10))
                }
                

                if (data.filter(data => data.testId === 'sex').length > 0) {
                    setSex(data.filter(data => data.testId === 'sex')[0].score)
                    setSexDate(data.filter(data => data.testId === 'sex')[0].submission_date.substring(0,10))
                }

                if (data.filter(data => data.testId === 'brainfog').length > 0) {
                    setBrainfog(data.filter(data => data.testId === 'brainfog')[0].score)
                    setBrainfogDate(data.filter(data => data.testId === 'brainfog')[0].submission_date.substring(0,10))
                }
                

                if (data.filter(data => data.testId === '36item_overall').length > 0) {
                    setSf36item(data.filter(data => data.testId === '36item_overall')[0].score)
                    setSf36itemDate(data.filter(data => data.testId === '36item_overall')[0].submission_date.substring(0,10))
                }
                
            } catch (error) {
                console.error('Error fetching test scores', error)
            }
        }
        fetchTestScores(userId)
    }, [])

    useEffect(() => {
        setCookieValue("walkspeed", "", -1);
        setCookieValue("grip", "", -1);
        setCookieValue("weight", "", -1);
        setCookieValue("systolic", "", -1);
        setCookieValue("diastolic", "", -1);
        setCookieValue("chototal", "", -1);
        setCookieValue("choldl", "", -1);
        setCookieValue("chohdl", "", -1);
        
        let cookieWalkSpeed = getCookie("walkspeed")
        if (cookieWalkSpeed) {
            setWalkSpeed(cookieWalkSpeed)
        } else if (formData) {
            setWalkSpeed(formData.walkspeed)
        }
        let cookieGrip = getCookie("grip")
        if (cookieGrip) {
            setGrip(cookieGrip)
        } else if (formData) {
            setGrip(formData.grip.toFixed())
        }

        let cookieWeight = getCookie("weight")
        if (cookieWeight) {
            setWeight(cookieWeight)
        } else if (formData) {
            setWeight(formData.weight.toFixed())
        }
        let cookieSystolic = getCookie("systolic")
        if (cookieSystolic){
            setSystolic(cookieSystolic)
        } else if (formData) {
            setSystolic(formData.systolic)
        }
        let cookieDiastolic = getCookie("diastolic")
        if (cookieDiastolic){
            setDiastolic(cookieDiastolic)
        } else if (formData) {
            setDiastolic(formData.diastolic)
        }
        let cookieChototal = getCookie("chototal")
        if (cookieChototal){
            setChototal(cookieChototal)
        } else if (formData) {
            setChototal(formData.chototal)
        }
        let cookieCholdl = getCookie("choldl")
        if (cookieCholdl){
            setCholdl(cookieCholdl)
        } else if (formData) {
            setCholdl(formData.choldl)
        }
        let cookieChohdl = getCookie("chohdl")
        if (cookieChohdl){
            setChohdl(cookieChohdl)
        } else if (formData) {
            setChohdl(formData.chohdl)
        }
       
    }, [formData])

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    var message1 = "entered is outside the expected range. Please review your entry before continuing.";
    var message2 = "Please review your entry before continuing. If the values entered are correct immediately consult with your healthcare provider.";
    var message3 = "Please review your entries before continuing. If the values entered are correct please consult with your healthcare provider.";

    const handleSubmit = (e) => {
        e.preventDefault()

        let progressRes = {}
        progressRes["user"] = userId
        progressRes["walkspeed"] = walkspeed
        progressRes["grip"] = grip
        progressRes["weight"] = weight
        progressRes["systolic"] = systolic
        progressRes["diastolic"] = diastolic
        progressRes["chototal"] = chototal
        progressRes["choldl"] = choldl
        progressRes["chohdl"] = chohdl
        try {
          submitData(progressRes);
        } catch (error) {
          console.error('Error submitting data:', error);
        }

        setSubmittedToday(true)
        setFirstSubmit(1);
        // window.location.reload(false)
    }

    const handleBlur = (field, value) => {
        if (field === 'walkspeed' && ((!/^(?!0\d)\d*(?:\.\d+)?$/.test(walkspeed) && walkspeed !== '') || walkspeed > walkLimit)) {
          setModalMessage("Warning: Walking Speed " + message1);
          setShowModal(true);
        } else if (field === 'grip' && ((!/^(?!0\d)\d*(?:\.\d+)?$/.test(grip) && grip !== '') || grip > gripLimit)) {
          setModalMessage("Warning: Grip Strength " + message1);
          setShowModal(true);
        } else if (field === 'weight' && ((!/^(?!0\d)\d*(?:\.\d+)?$/.test(weight) && weight !== '') || weight > weightLimit || ((weight < weightMin) && weight !== ''))) {
          setModalMessage("Warning: Weight " + message1);
          setShowModal(true);
        } else if (field === 'systolic' && ((!/^(?!0\d)\d*(?:\.\d+)?$/.test(systolic) && systolic !== '') || ((systolic > 180 || systolic < 90) && systolic !== ''))) {
          var highOrLow = systolic > 180 ? "high" : "low";
          setModalMessage("Warning: Systolic is abnormally " + highOrLow + ". " + message2);
          setShowModal(true);
        } else if (field === 'diastolic' && ((!/^(?!0\d)\d*(?:\.\d+)?$/.test(diastolic) && diastolic !== '') || ((diastolic > 120 || diastolic < 60) && diastolic !== ''))) {
          var highOrLow = diastolic > 120 ? "high" : "low";
          setModalMessage("Warning: Diastolic is abnormally " + highOrLow + ". " + message2);
          setShowModal(true);
        } else if (field === 'chototal' && ((!/^(?!0\d)\d*(?:\.\d+)?$/.test(chototal) && chototal !== '') || chototal > 299 || ((chototal < 120) && chototal !== ''))) {
          var highOrLow = chototal > 299 ? "high" : "low";
          setModalMessage("Warning: Total Cholesterol is abnormally " + highOrLow + ". " + message3);
          setShowModal(true);
        } else if (field === 'choldl' && ((!/^(?!0\d)\d*(?:\.\d+)?$/.test(choldl) && choldl !== '') || choldl > 190 || ((choldl < 50) && choldl !== ''))) {
          var highOrLow = choldl > 190 ? "high" : "low";
          setModalMessage("Warning: LDL is abnormally " + highOrLow + ". " + message3);
          setShowModal(true);
        } else if (field === 'chohdl' && ((!/^(?!0\d)\d*(?:\.\d+)?$/.test(chohdl) && chohdl !== '') || (chohdl < 40 && chohdl !== ''))) {
          setModalMessage("Warning: HDL is abnormally low. " + message3);
          setShowModal(true);
        }
        field = ''
      };
      

    return (
        <div style={{backgroundColor: "#F5F5F5"}}>
            <div className="questionnaire_title">Update Progress</div>
            <div className="cal_container">
                <Form onSubmit={handleSubmit}>
                    <div className="inlineContainer">
                        Hi {firstName}, Check your progress by filling out your Progress Sheet!
                        <p style={{fontSize: '14px', marginTop: '1rem'}}>1 submission per day. If a piece of information hasn't changed, leave it as it was previously</p>
                    </div>
                    <Form.Group>
                        <div className="inlineContainer">
                            <Form.Text>General Information</Form.Text>
                        </div>

                        <div className="cal_select_group">
                            <Form.Label>Walking Speed ({walkUnit})</Form.Label>
                            <Form.Control
                                style={{width: '100px'}}
                                type="number"
                                placeholder=""
                                id="walkspeed"
                                name="walkspeed"
                                value={walkspeed}
                                min="0"
                                className="cal_input_control"
                                required
                                onBlur={(e) => handleBlur('walkspeed', e.target.value)}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    if(newValue >=0){
                                        setWalkSpeed(newValue);
                                        setCookieValue("walkspeed", newValue)
                                    }
                                    else {
                                        setWalkSpeed(0);
                                        setCookieValue("walkspeed", 0)
                                    }
                                }}
                            ></Form.Control>
                        </div>

                        <div className="cal_select_group">
                            <Form.Label>Grip Strength ({weightUnit})</Form.Label>
                            <Form.Control
                                style={{width: '100px'}}
                                type="number"
                                placeholder=""
                                id="grip"
                                name="grip"
                                value={grip}
                                min="0"
                                className="cal_input_control"
                                required
                                onBlur={(e) => handleBlur('grip', e.target.value)}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    if(newValue >=0){
                                        setGrip(newValue);
                                        setCookieValue("grip", newValue)
                                    }
                                    else {
                                        setGrip(0);
                                        setCookieValue("grip", 0)
                                    }
                                }}
                            ></Form.Control>
                        </div>

                        <div className="cal_select_group">
                            <Form.Label>Weight ({weightUnit})</Form.Label>
                            <Form.Control
                                style={{width: '100px'}}
                                type="number"
                                placeholder=""
                                id="weight"
                                name="weight"
                                value={weight}
                                min="0"
                                step="1"
                                className="cal_input_control"
                                required
                                onBlur={(e) => handleBlur('weight', e.target.value)}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    if(newValue >=0){
                                        setWeight(newValue);
                                        setCookieValue("weight", newValue)
                                    }
                                    else {
                                        setWeight(0);
                                        setCookieValue("weight", 0)
                                    }
                                }}
                            ></Form.Control>
                        </div>

                        <div className="cal_select_group">
                            <Form.Label>Blood Pressure
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            </Form.Label>
                            <Form.Control
                                style={{width: '100px'}}
                                type="number"
                                placeholder="systolic"
                                id="systolic"
                                name="systolic"
                                value={systolic}
                                min="0"
                                className="cal_input_control"
                                required
                                onBlur={(e) => handleBlur('systolic', e.target.value)}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    if(newValue >=0){
                                        setSystolic(newValue);
                                        setCookieValue("systolic", newValue)
                                    }
                                    else {
                                        setSystolic(0);
                                        setCookieValue("systolic", 0)
                                    }
                                }}
                            ></Form.Control>

                            <Form.Label>Over</Form.Label>

                            <Form.Control
                                style={{width: '100px'}}
                                type="number"
                                placeholder="diastolic"
                                id="diastolic"
                                name="diastolic"
                                value={diastolic}
                                min="0"
                                className="cal_input_control"
                                required
                                onBlur={(e) => handleBlur('diastolic', e.target.value)}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    if(newValue >=0){
                                        setDiastolic(newValue);
                                        setCookieValue("diastolic", newValue)
                                    }
                                    else {
                                        setDiastolic(0);
                                        setCookieValue("diastolic", 0)
                                    }
                                }}
                            ></Form.Control>
                        </div>
                        
                        <div className="cal_select_group">
                            <Form.Label>Cholesterol:</Form.Label>
                        </div>
                        <div className="cal_select_group">
                            <Form.Label>&emsp;&emsp;Total</Form.Label>
                            <Form.Control
                                style={{width: '100px'}}
                                type="number"
                                placeholder=""
                                id="chototal"
                                name="chototal"
                                value={chototal}
                                min="0"
                                className="cal_input_control"
                                required
                                onBlur={(e) => handleBlur('chototal', e.target.value)}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    if(newValue >=0){
                                        setChototal(newValue);
                                        setCookieValue("chototal", newValue)
                                    }
                                    else {
                                        setChototal(0);
                                        setCookieValue("chototal", 0)
                                    }
                                }}
                            ></Form.Control>
                        </div>
                        <div className="cal_select_group">
                            <Form.Label>&emsp;&emsp;LDL</Form.Label>
                            <Form.Control
                                style={{width: '100px'}}
                                type="number"
                                placeholder=""
                                id="choldl"
                                name="choldl"
                                value={choldl}
                                min="0"
                                className="cal_input_control"
                                required
                                onBlur={(e) => handleBlur('choldl', e.target.value)}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    if(newValue >=0){
                                        setCholdl(newValue);
                                        setCookieValue("choldl", newValue)
                                    }
                                    else {
                                        setCholdl(0);
                                        setCookieValue("choldl", 0)
                                    }
                                }}
                            ></Form.Control>
                        </div>
                        <div className="cal_select_group">
                            <Form.Label>&emsp;&emsp;HDL</Form.Label>
                            <Form.Control
                                style={{width: '100px'}}
                                type="number"
                                placeholder=""
                                id="chohdl"
                                name="chohdl"
                                value={chohdl}
                                min="0"
                                className="cal_input_control"
                                required
                                onBlur={(e) => handleBlur('chohdl', e.target.value)}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    if(newValue >=0){
                                        setChohdl(newValue);
                                        setCookieValue("chohdl", newValue)
                                    }
                                    else {
                                        setChohdl(0);
                                        setCookieValue("chohdl", 0)
                                    }
                                }}
                            ></Form.Control>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <div className="inlineContainer" style={{marginTop: "60px"}}>
                            {!submittedToday && (
                                <Button
                                    type="submit"
                                    variant="dark"
                                    style={{minWidth: "225px", height: "45px"}}
                                >
                                    Submit
                                </Button>
                            )}
                            {submittedToday && (
                                <Button
                                    type="submit"
                                    variant="dark"
                                    style={{minWidth: "225px", height: "45px"}}
                                    disabled
                                >
                                    Submit
                                </Button>
                            )}
                            {firstSubmit ? (
                                <p style={{color: "gray", fontSize: "14px"}}>(Saved)</p>
                            ) : submittedToday ? (
                                <p style={{color: "gray", fontSize: "14px"}}>(Already submitted today)</p>): null}
                        </div>
                    </Form.Group>
                    <Form.Group>
                    <div className="inlineContainer">
                            <Form.Text>Tests</Form.Text>
                            <p style={{color: "gray", fontSize: "14px"}}>(Click Test Name to Take Test)</p>
                        </div>

                        <div className="cal_select_group">
                            <Form.Label style={{color: "blue"}}>
                                <Nav.Link href="/pittsleeptest">Pittsburgh Sleep Quality Index</Nav.Link> 
                            </Form.Label>
                        <Form.Label>Score: {pitt}, Date: {pittDate}</Form.Label>
                        </div>

                        <div className="cal_select_group">
                            <Form.Label style={{color: "blue"}}>
                                <Nav.Link href="/stresstest">Perceived Stress Scale</Nav.Link>
                            </Form.Label>
                            <Form.Label>Score: {stress}, Date: {stressDate}</Form.Label>
                        </div>

                        <div className="cal_select_group">
                            <Form.Label style={{color: "blue"}}>
                                <Nav.Link href="/digitspantest">Digit Memory Test</Nav.Link>
                            </Form.Label>
                            <Form.Label>Score: {digit}, Date: {digitDate}</Form.Label>
                        </div>

                        <div className="cal_select_group">
                            <Form.Label style={{color: "blue"}}>
                                <Nav.Link href="/reactiontimetest">Reaction Time Test</Nav.Link>
                            </Form.Label>
                            <Form.Label>Score: {reactiontime}, Date: {reactiontimeDate}</Form.Label>
                        </div>

                        <div className="cal_select_group">
                            <Form.Label style={{color: "blue"}}>
                                <Nav.Link href="/selfreactiontest">Self Memory Evaluation</Nav.Link>
                            </Form.Label>
                            <Form.Label>Score: {selfreaction}, Date: {selfreactionDate}</Form.Label>
                        </div>

                        <div className="cal_select_group">
                            <Form.Label style={{color: "blue"}}>
                                <Nav.Link href="/happinesstest">Oxford Happiness Questionnaire</Nav.Link>
                            </Form.Label>
                            <Form.Label>Score: {happiness}, Date: {happinessDate}</Form.Label>
                        </div>

                        <div className="cal_select_group">
                            <Form.Label style={{color: "blue"}}>
                                <Nav.Link href="/qoltest">Quality of Life Scale</Nav.Link>
                            </Form.Label>
                            <Form.Label>Score: {qol}, Date: {qolDate}</Form.Label>
                        </div>

                        <div className="cal_select_group">
                            <Form.Label style={{color: "blue"}}>
                                <Nav.Link href="/appearancetest">Appearance Scale</Nav.Link>
                            </Form.Label>
                            <Form.Label>Score: {appearance}, Date: {appearanceDate}</Form.Label>
                        </div>

                        <div className="cal_select_group">
                            <Form.Label style={{color: "blue"}}>
                                <Nav.Link href="/sextest">Sex Questionnaire</Nav.Link>
                            </Form.Label>
                            <Form.Label>Score: {sex}, Date: {sexDate}</Form.Label>
                        </div>

                        <div className="cal_select_group">
                            <Form.Label style={{color: "blue"}}>
                                <Nav.Link href="/brainfogtest">Brain Fog Scale</Nav.Link>
                            </Form.Label>
                            <Form.Label>Score: {brainfog}, Date: {brainfogDate}</Form.Label>
                        </div>

                        <div className="cal_select_group">
                            <Form.Label style={{color: "blue"}}>
                                <Nav.Link href="/sf36test">General Health Inventory</Nav.Link>
                            </Form.Label>
                            <Form.Label>Score: {sf36item}, Date: {sf36itemDate}</Form.Label>
                        </div>
                    </Form.Group>
                </Form>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" keyboard={false} centered className="custom-modal">
                <Modal.Body>{modalMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowModal(false)}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
            
        </div>
    )
}

export default Progress
