import React,{useState} from 'react'
import { useAuthContext } from "../hooks/useAuthContext"
import Select from "../components/CustomizedSelect/Select"
import './About.css'

function About() {

  const { user } = useAuthContext()
  const [diabetes, setDiabetes] = useState(false);

  return (
    <div className="about">
        <div className="background">
          `<div className="about_us_text normal">
              <h5 className="headFont">The Story Behind Our Software</h5>
              
              <h6 className="largeFont">By Dr. John Leddo, Founder</h6>

              <p>Throughout human history, we’ve assumed that growing old and losing our health was inevitable.  In recent years, scientists have begun to challenge this assumption and ask whether there are things people can do to slow down and even reverse their aging processes. This gave rise to the idea of biological age. Chronological age is how long ago you were born.  Biological age is how old your body is from a functional perspective. Chronological age and biological can often be different. </p>
              <p>We’ve all seen people who are older in years, yet they seem much younger and more vital than they actually are.
              Before I got married, I went to China with my then fiancée, Yihong, to ask her mom’s permission to marry Yihong. Yihong’s mom was almost 80 at the time and I expected that I would be spending my time sitting on a couch and drinking tea with her.  Instead, Yihong’s mom took me to the local park and taught me Chinese sword fighting. There I was, practicing Chinese sword fighting with an almost 80-year-old woman. Clearly, her biological age was much younger than her chronological age. </p> 
              <p>On the other hand, we’ve also seen the opposite.  People who are chronologically young but seem tired, run-down and even prone to illness.  Those people are aging faster than normal.
              For years, I’ve been trying to do as much as I can to maintain my health and slow my own aging process.  I do intermittent fasting, eat healthy foods, exercise, take nutritional supplements, get good quality sleep, manage my stress and even take saunas a few times a week.  The results have been amazing for me.  I’m at retirement age, but I still work 7 days a week. I cannot remember the last time I took a sick day from work, although I know it was more than 10 years ago. My doctor visits consist of annual checkups, blood tests, and vaccinations.</p>
              <p>I wanted to see if the things I do to help slow down my aging process and build my health could work for others. I initiated a research project with middle-aged adults.  We started by taking DNA samples and sending them to a lab to measure  the participants’ biological ages. Then, we put them on a four-month program of diet, exercise, nutritional supplements, stress management and healthy sleep habits.  At the end of the four months, we took DNA samples again and sent them to the lab to see what happened to our participants’ biological ages.
              Logically, one might expect that since four months had passed, each person would be biologically four months older. Instead, we found that every person, 100% of the participants, were now biologically younger than when they started the program.  The range was 5- 12 years. In other words, after just four months, each person grew biologically younger by at least 5 years and as much as 12 years.</p>
              <p>I wanted to bring that type of result to the world.  Imagine the humanitarian benefits if people could live longer and be healthier in doing so.  They could spend more time with their families and loved ones.  They would have more time and better health to do they things they love to do. They would be more productive in society.  Our country spends trillions of dollars in healthcare each year.  Imagine if we could cut that greatly because people are healthy.  We would have more resources to spend on things like education, scientific research, job training, improving the environment and solving societal problems like hunger and homelessness.</p>
              <p>This humanitarian goal gave rise to our software. We want to take the latest research in health and longevity and use machine learning and data science to make personalized recommendations to people on what they can do to live longer and be healthier. 
              Our software will make recommendations that include:</p>
              <p>
              <ul>
              <li>Diet (when, what and how much to eat)</li>
              <li>Exercise (cardiovascular, resistance/strength training, stretching, bone density, balance)</li>
              <li>Nutritional supplements</li>
              <li>Stress reduction</li>
              <li>Healthy sleep</li>
              <li>Temporary stress-inducing activities like exposure to heat or cold </li>
              </ul>
              </p>
              <p>Our software is based on review of hundreds of scientific articles and surveys of hundreds of people’s lifestyles and health outcomes, reviews that are continually updating.</p>
              <p>Our software uses state-of-the-art data science and machine learning techniques are used to customize the results of this research to make personalized recommendations. There is no “one-size-fits-all” approach.</p>
              <p>The software lets you adjust your personalized plan to fit your lifestyle and will show you your projected benefits in lifespan extension and health of your updated plan.</p>
              <p>The software provides personal logs that track your lifestyle and health outcomes and uses data analytics to determine what is working and not working for you and how to continuously improve your health and longevity outcomes.</p>
              <p>Our website includes a resource center that provides information and videos on why each intervention works and how to carry out recommended activities, including exercise, meditation, breathing, relaxation, and yoga videos.</p>
              <p>Our website includes a social media forum where members can talk and exchange tips and success stories and provide each other with encouragement and support.</p>
              <p>You can start by filling out a registration form and then filling out a short questionnaire to get a free estimate of your expected lifespan along with an estimate of what our program can do to help you live a longer and healthier life.</p>
              <p>If you decide to become a member, you can do a full registration and intake form, and we will provide you with a personalized program.  We recommend you show this personalized program to your doctor before beginning it and make any adjustments your doctor recommends. Membership is just $9.99 per month and there is no long-term contract to sign or minimum number of months in the membership.</p>
              <p>It is my wish that together, we can create a world in which everyone lives a long, happy, healthy and productive life. Your life is a tremendous gift, and I hope our team can help you make the most of it.</p>
            
          </div>`
        </div>
        {/*<p>About page here</p>
        {user && <p>{user.firstName}</p> }*/}
        
    </div>
  )
}

export default About
