import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "../CardForm.css";
import "../SubscriptionPlans.css";
const baseUrl = process.env.REACT_APP_baseUrl;
const CardForm = ({ onSuccessfulUpdate }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    // const { error, token } = await stripe.createToken(
    //   elements.getElement(CardElement),
    // );
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (!error) {
      onSuccessfulUpdate(paymentMethod.id);
    } else {
      console.log(error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="stripe-card-form">
      <CardElement className="stripe-card-element" />
      <button type="submit" disabled={!stripe} className="stripe-submit-btn">
        Update
      </button>
    </form>
  );
};

const PaymentUpdate = () => {
  const [paymentSuccess, setUpdateSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_KEY,
  );

  const onSuccessfulUpdate = async (paymentMethodId) => {
    setIsSubmitting(true);
    
    // Send the new payment method to the backend to update Stripe
    const token = localStorage.getItem("idToken").replace(/^"(.*)"$/, "$1");
    console.log('paymentID:', paymentMethodId);

    const response = await axios.post(
        `${baseUrl}api/users/payment-update/`,
        { paymentMethodId},  // Send the new Payment Method ID
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        }
      );

      if (response.data.message === 'Payment method updated successfully') {
        setUpdateSuccess(true);  // Handle success, show confirmation to the user
        setTimeout(() => {
          navigate("/");
        }, 3000);
    } else {
        console.error('Payment update failed:', response.data.message);
    }



    // .then(() => {
    //   setPaymentSuccess(true);
    //   setTimeout(() => {
    //     navigate("/");
    //   }, 3000);
    // })
    // .catch((error) => {
    //   console.error(
    //     "Update error:",
    //     error.response ? error.response.data : error.message,
    //   );
    // })
  setIsSubmitting(false);
}

  return (
    <div>
      {paymentSuccess ? (
        <div className="payment-update" >
          <h2>Update successful!</h2>
          <p>Redirecting to homepage...</p>
        </div>
      ) : (
        <div className="payment-update">
          <h2>Payment Update</h2>
          <br />
          <Elements stripe={stripePromise}>
            <CardForm onSuccessfulUpdate={onSuccessfulUpdate} />
          </Elements>
        </div>
      )}
      {isSubmitting && (
        <div className="loading-overlay">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default PaymentUpdate;
