import React, { useEffect, useState } from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function InputSingle({label, options, disabled, instruction, name, onChange, onChangeUnit, onChangeUnitDefault, value, required=true, error, limit}) {
    // const [error, setError] = useState('');

    if(!options){
        options = [];
    }

    useEffect(() => {
        if (options.length > 1 && onChangeUnitDefault && typeof onChangeUnitDefault === 'function') {
            onChangeUnitDefault(name, options[0]);
        }
    }, [])

    useEffect(() => {
        const handleWheel = (event) => {
            if (document.activeElement.type === "number") {
                document.activeElement.blur();
            }
        };
    
        document.addEventListener("wheel", handleWheel);
    
        return () => {
            document.removeEventListener("wheel", handleWheel);
        };
    }, []);
    

    const handleChange = (event) => {
        if(Number(event.target.value) < 0){
            event.target.value = 0;
        }
        onChange(name, event.target.value);
    };

    const handleUnitChange = (event) => {
        if(event.target.value !== options[0]){
            onChangeUnitDefault(name, options[0])
        }
        onChangeUnit(name, event.target.value);
    };

    const errorMessage = 'Error: please fill out this field, or enter 0.'

  return (
        <div class="col">
            <div class="d-flex justify-content-center">
                <label>{label}</label>
            </div>
            {/* <div class="input-group" style={errors.name && {border: 'thick solid red'}}> */}
            <div class="input-group">
                <input type='number' class="form-control" disabled={disabled} onChange={handleChange} value={value} />
            {
            options.length > 1 ? (
                <div class="input-group-append">
                    <select class="form-control" onChange={handleUnitChange}>     
                        {options.map((option, index) => (
                            <option value={option} id={index} key={index}>{option}</option>                         
                        ))}
                    </select>
                </div>
            ) : options.length === 1 ?(
                <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2" value={options[0]}>{options[0]}</span>
                </div>
            ) : null
            }
            </div>
            {/* {limit && <p style={{color: 'blue', fontSize: '16px', marginBottom: '0'}}>current limit is {limit}</p>} */}
            {Number(value) > Number(limit) && <p style={{color: 'red', fontSize: '16px', marginBottom: '0'}}>Warning! You have exceeded the maximum limit of {limit}.</p>}
            {error && <p style={{color: 'red', fontSize: '16px', marginBottom: '0'}}>{error}</p>}
            {instruction &&
                <div class="d-flex justify-content-center">
                    <small class="text-muted">{instruction}</small>
                </div>
            }
        </div>
  )
}
