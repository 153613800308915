import React from "react";
import OrganizationContent from "./OrganizationContent";

const Organization = () => {
  return (
    <OrganizationContent orgtype="C" />
  );
};

export default Organization;
