const lCardio = 'cardio_low'
const mCardio = 'cardio_medium'
const hCardio = 'cardio_high'
const strength = 'strength_training'
const balance = 'balance'
const boneDensity = 'bone_density'
const stretching = 'stretching'
const saunaFreq = 'sauna_frequency'
const saunaDuration = 'sauna_duration'


export {lCardio, mCardio, hCardio, strength, balance, boneDensity,
    stretching, saunaFreq, saunaDuration}