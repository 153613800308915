import React from 'react';

export default function SupplementWarning() {
    return (
        <p style={{fontWeight: '520'}}>
            WARNING: Supplementation is a very individualized matter and 
            the types and quantities of supplements taken should always be 
            discussed with your primary provider before consumption. 
            Excess consumption of supplements can pose a serious health risk 
            and should not be taken without professional advice.
        </p>
          
    )
}