const upsetCountLabel = "How often have you been upset because of something that happened unexpectedly?";
const controlCountLabel = "How often have you felt that you were unable to control the important things in your life?";
const stressCountLabel = "How often have you felt nervous and 'stressed'?";
const confidenceCountLabel = "You have not felt confident about your ability to handle your personal problems?";
const wayCountLabel = "You have felt that things were not going your way?";
const copeCountLabel = "You have found that you could not cope with all the things that you had to do?";
const irritationsCountLabel = "You have not been able to control irritations in your life?";
const topCountLabel = "You have felt that you were not on top of things?";
const angerCountLabel = "You have been angered because of things that were outside of your control?";
const difficultiesCountLabel = "You have felt difficulties were piling up so high that you could not overcome them?";

export { upsetCountLabel, controlCountLabel, stressCountLabel, confidenceCountLabel, wayCountLabel, copeCountLabel, irritationsCountLabel, topCountLabel, angerCountLabel, difficultiesCountLabel };