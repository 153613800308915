import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import './PopUpWarning.css';

const nameConversion = {
  vitamin_a: 'Vitamin A',
  vitamin_b6: 'Vitamin B6',
  vitamin_b12: 'Vitamin B12',
  folic_acid: 'Folic Acid',
  vitamin_c: 'Vitamin C',
  vitamin_d: 'Vitamin D',
  vitamin_e: 'Vitamin E',
  vitamin_k: 'Vitamin K',
  calcium: 'Calcium',
  dietary_fiber: 'Dietary Fiber',
  fish_oil_omega_3: 'Fish Oil Omega 3',
  green_tea: 'Green Tea',
  iron: 'Iron',
  magnesium: 'Magnesium',
  olive_oil: 'Olive Oil',
  protein_supplements: 'Protein Supplements',
  zinc: 'Zinc',
  water: 'Water',
}

const PopUpWarning = (props) => {
  return (
    <Modal
      show={props.showModal.show}
      // onHide={() => props.setShowModal(false)}
      backdrop={false}
      keyboard={false}
      centered
      className='custom-modal'
    >
      <Modal.Body>{nameConversion[props.showModal.name]} entered is greater than expected value. Please click OK to acknowledge your input.</Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={props.handleConfimation}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopUpWarning;
