export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + '=') {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
  
export  function setCookieValue(key,value){
  var now = new Date();

// 设置 Cookie 过期时间为一天后
//  var expires = now.getTime() + (1 * 1000);
//  expires = new Date(expires).toUTCString();
// do we need to write expire date
    document.cookie = key+"="+value //";expires=" + expires;
  }
  