import React from 'react'
import InputMulti from '../../../components/questionnaireElement/InputMulti'
import { useEffect, useState } from 'react'
import {
    bedtimeLabel, sleepOnsetLabel, wakeUpTimeLabel, actualSleepHoursLabel,
    sleepQualityLabel, sleepMedicationLabel, stayAwakeTroubleLabel, enthusiasmLevelLabel,
    fallAsleepDifficultyLabel, wakeUpMidnightLabel, bathroomNeedsLabel, breathingDifficultyLabel,
    loudSnoringLabel, coldFeetLabel, hotFeetLabel, badDreamsLabel, painLabel
} from '../Sections/Lables/MySleepLabels'
import {
    bedtimeName, sleepOnsetName, wakeUpTimeName, actualSleepHoursName,
    sleepQualityName, sleepMedicationName, stayAwakeTroubleName, enthusiasmLevelName,
    fallAsleepDifficultyName, wakeUpMidnightName, bathroomNeedsName, breathingDifficultyName,
    loudSnoringName, coldFeetName, hotFeetName, badDreamsName, painName
} from '../Sections/NameTable/MySleepName'
import { sleepOptions, sleepQualityOptions, frequencyOptions, enthusiasmLevelOption } from '../Sections/OptionsTable/MySleepOptions'
const formDataKey = 'IntakeForm'; 
export default function MySleep({setHasChanges, dataState, setDataState, errors}) {
  const [cigaretteStatus, setCigaretteStatus] = useState('');
  const [formData, setformData] = useState(sessionStorage.getItem(formDataKey) || {});
  useEffect(() => {
    const savedData = sessionStorage.getItem(formDataKey);
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setformData(parsedData);
    }
  }, []);
  const handleInputChange = (name, value) => {
    setHasChanges(true);
    
    let newValue = {...dataState, [name]:['dob', 'toBedTime', 'getUpTime'].includes(name) ?
    value : parseFloat(value)}
    setDataState(newValue)
  };
  return (
          <form>
            <div class='inlineContainer'>
              <small class="form-text">My Sleep</small>
            </div>
            <InputMulti inputs={[
              {type: 'InputTime', label: bedtimeLabel,  name: bedtimeName},
              {type: 'InputSingle', label: sleepOnsetLabel,  name: sleepOnsetName},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors}
            />
            <InputMulti inputs={[
              {type: 'InputTime', label: wakeUpTimeLabel, name: wakeUpTimeName},
              {type: 'InputSingle', label: actualSleepHoursLabel, name: actualSleepHoursName},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors}
            />
            <div class='inlineContainer'>
              <div>How often during the past month have you had trouble falling asleep because you...</div>
            </div>
            <InputMulti inputs={[
              {type: 'Menu', label: fallAsleepDifficultyLabel, options: sleepOptions, name: fallAsleepDifficultyName},
              {type: 'Menu', label: wakeUpMidnightLabel, options: sleepOptions, name: wakeUpMidnightName},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors}
            />
            <InputMulti inputs={[
              {type: 'Menu', label: bathroomNeedsLabel, options: sleepOptions, name: bathroomNeedsName},
              {type: 'Menu', label:  breathingDifficultyLabel, options: sleepOptions, name:  breathingDifficultyName},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors}
            />
            <InputMulti inputs={[
              {type: 'Menu', label: loudSnoringLabel, options: sleepOptions, name: loudSnoringName},
              {type: 'Menu', label: coldFeetLabel, options: sleepOptions, name: coldFeetName},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors}
            />
            <InputMulti inputs={[
              {type: 'Menu', label: hotFeetLabel, options: sleepOptions, name: hotFeetName},
              {type: 'Menu', label: badDreamsLabel, options: sleepOptions, name: badDreamsName},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors}
            />
            <InputMulti inputs={[
              {type: 'Menu', label: painLabel, options: sleepOptions, name: painName},
              {type: ''},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors}
            />
            <div class='inlineContainer'>
              <div></div>
            </div>
            <InputMulti inputs={[
              {type: 'Menu', label: sleepQualityLabel, options: sleepQualityOptions, name: sleepQualityName},
              {type: 'Menu', label: sleepMedicationLabel, options: sleepOptions, name: sleepMedicationName},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors}
            />
            <InputMulti inputs={[
              {type: 'Menu', label: stayAwakeTroubleLabel, options: sleepOptions, name: stayAwakeTroubleName},
              {type: 'Menu', label: enthusiasmLevelLabel, options: enthusiasmLevelOption, name: enthusiasmLevelName},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors}
            />
          </form>
  )
}
