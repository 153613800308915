const processedMeatLabel = "Processed Meat";
const poultryLabel = "Poultry";
const redMeatLabel = "Red Meat";
const fishLabel = "Fish";
const refinedGrainLabel = "Refined Grain";
const wholeGrainLabel = "Whole Grain";
const vegetablesLabel = "Vegetables";
const fruitLabel = "Fruit";
const fruitAndVegetableLabel = "Fruits and Vegetables";
const legumesLabel = "Legumes";
const milkLabel = "Milk";
const cheeseLabel = "Cheese";
const butterLabel = "Butter";
const dairyOtherLabel = "Other Dairy Products (Yogurt, Cream, etc)";
const sugarySodaLabel = "Soda";
const sugaryCakesLabel = "Cakes";
const sugaryCookiesLabel = "Cookies";
const sugaryCandyLabel = "Candy";
const sugaryIceCreamLabel = "Ice Cream";
const sugaryAddedSugarLabel = "Added Sugar";
const sugaryOtherLabel = "Other Sugary Items";
const artificiallySodaLabel = "Soda";
const artificiallyCakesLabel = "Cakes";
const artificiallyCookiesLabel = "Cookies";
const artificiallyCandyLabel = "Candy";
const artificiallyIceCreamLabel = "Ice Cream";
const artificiallyAddedSugarLabel = "Added Sugar";
const artificiallyOtherLabel = "Other Artificially Sweetened Items";
const waterIntakeLabel = "How much water do you drink per day?";
const saturatedFatIntakeLabel = "About how many grams of saturated fat do you consume per day?";
const transFatIntakeLabel = "About how many grams of trans fat do you consume per day?";
const intermittentFastingLabel = "Do you use an intermittent fasting eating schedule?";
const calorieRestrictionLabel = "Do you restrict your calorie intake?";
const refinedSugarLabel = 'How many grams of refined sugar do you consume per day?';
const artificialSugarLabel = 'How many grams of artificially sweetened sugar do you consume per day?';

export {
    processedMeatLabel, poultryLabel, redMeatLabel, fishLabel, refinedGrainLabel, wholeGrainLabel,
    vegetablesLabel, fruitLabel, fruitAndVegetableLabel, legumesLabel, milkLabel, cheeseLabel, butterLabel, dairyOtherLabel,
    sugarySodaLabel, sugaryCakesLabel, sugaryCookiesLabel, sugaryCandyLabel, sugaryIceCreamLabel,
    sugaryAddedSugarLabel, sugaryOtherLabel, artificiallySodaLabel, artificiallyCakesLabel,
    artificiallyCookiesLabel, artificiallyCandyLabel, artificiallyIceCreamLabel,
    artificiallyAddedSugarLabel, artificiallyOtherLabel, waterIntakeLabel, saturatedFatIntakeLabel,
    transFatIntakeLabel, intermittentFastingLabel, calorieRestrictionLabel, refinedSugarLabel,
    artificialSugarLabel
};
