import React from 'react'
import InputSingle from './InputSingle'
import Menu from './Menu'
import DateInput from './DateInput';
import InputCheckBox from './InputCheckBox';
import InputTime from './InputTime';
import CalculatorInputSingle from './CalculatorInputSingle';


export default function InputMulti({inputs, onChange, onChangeUnit, onChangeUnitDefault, formData, formUnit, formDataUnit, value, instruction, error, limit}) {
  return (
    <div className="form-group row mb-1">
      {inputs.map((input, index) => {
          if (input.type === 'Menu') {
              return <Menu key={index} label={input.label} options={input.options} name={input.name} onChange={onChange} value={value[input.name]} error={error[input.name]}/>;
          } else if (input.type === 'InputSingle') {
              return <InputSingle key={index} label={input.label} options={input.options} name={input.name}
               onChange={onChange} onChangeUnit={onChangeUnit} onChangeUnitDefault={onChangeUnitDefault}
               formUnit={formUnit} formDataUnit={formDataUnit}
               disabled={input.disabled} instruction={input.instruction} value={value[input.name]} error={error[input.name]} limit={limit ? limit[input.name] : Infinity}/>;
          } else if (input.type === 'DateInput') {
              return <DateInput key={index} label={input.label} options={input.options} name={input.name}
               onChange={onChange} disabled={input.disabled} instruction={input.instruction} value={value[input.name]} error={error[input.name]} max={input.max} />;
          } else if (input.type === 'InputCheckBox'){
              return <InputCheckBox key={index} label={input.label} options={input.options} name={input.name} onChange={onChange} value={value[input.name]} error={error[input.name]}/>
          } else if (input.type === 'InputTime'){
            return <InputTime key={index} label={input.label} options={input.options} name={input.name} onChange={onChange} value={value[input.name]} error={error[input.name]}/>
          } else if (input.type === 'CalculatorInputSingle'){
            return <CalculatorInputSingle key={index} label={input.label} options={input.options} name={input.name} onChange={onChange} value={value[input.name]} error={error[input.name]} currentUnit={formUnit[input.name]} onChangeUnit={onChangeUnit}
        onChangeUnitDefault={onChangeUnitDefault} />
          } 
          else {
            return <div class="col" key={index}> </div>;
          }
      })}
    </div>
  )
}
