import axios from 'axios'
import {getCookie} from "../../utils/cookie"

const baseUrl = process.env.REACT_APP_baseUrl;
const baseUrlAWS =`${baseUrl}api/myprogress`

const baseUrlUser = `${baseUrl}api/users`

//call API to retrieve the form data
export const fetchData = (userId) => {
    return axios.get(
        `${baseUrlAWS}/read-latest-progress-by-user/${userId}`,
        {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(response => response)
        .catch(error => {
            if (error.response && error.response.status === 500) {
                return null
            } else {
                console.error('Error fetching form data:', error)
                throw error
            }
        })

}

export const fetchScores = (userId) => {
    return axios.get(
        `${baseUrlAWS}/read-latest-test-scores-by-user/${userId}`,
        {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(response => response.data.all_latest_scores_responses)
        .catch(error => {
            if (error.response && error.response.status === 500) {
                return null
            } else {
                console.error('Error fetching form data:', error)
                throw error
            }
        })
}

// call API to submit the form data 
export const submitData =  async (formData) => {
    const csrfToken = getCookie("csrftoken")
    console.log(JSON.stringify(formData))
    axios.post(
        `${baseUrlAWS}/create-progress-response/`,
        formData,
        {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken,
            },
        })
        .then(response => {
            console.log(response.data)
        })
        .catch(error => {
            if (error.response && error.response.status === 500) {
                return null
            } else {
                console.error('Error fetching form data:', error)
                throw error
            }
        })
}

export const fetchUserData = (userId) => {
    return axios.get(
        `${baseUrlUser}/get-user-data/${userId}`,
        {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(response => response.data)
        .catch(error => {
            if (error.response && error.response.status === 500) {
                return null
            } else {
                console.error('Error fetching form data:', error)
                throw error
            }
        })

}