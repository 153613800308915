const myMedicalHistoryNames = [
    "youInsulinResistance",
    "youType2Diabetes",
    "youHighBloodPressure",
    "youDyslipidemia",
    "youCardiovascularDisease",
    "youStroke",
    "youArthritis",
    "youSleepApnea",
    "youGallbladderDisease",
    "youHyperuricemia",
    "youOsteoarthritis",
    "youCancer",
    "youDepression",
    "youOtherDiseases"
    // "my-insulinResistance",
    // "my-type2Diabetes",
    // "my-highBloodPressure",
    // "my-dyslipidemia",
    // "my-cardiovascularDisease",
    // "my-stroke",
    // "my-arthritis",
    // "my-sleepApnea",
    // "my-gallbladderDisease",
    // "my-hyperuricemia",
    // "my-osteoarthritis",
    // "my-certainCancers",
    // "my-depression",
    // "my-other"
];


export {myMedicalHistoryNames};