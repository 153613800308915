import React from 'react'
import Menu from '../../../components/questionnaireElement/Menu'
import InputSingle from '../../../components/questionnaireElement/InputSingle'
import InputMulti from '../../../components/questionnaireElement/InputMulti'
import DateInput from '../../../components/questionnaireElement/DateInput'
import {
  DATE_OF_BIRTH, GENDER, CIGARETTES_USE, AGE,
  FORMER_CIGARETTES_YEARS, CURRENT_CIGARETTES_YEARS, ALCOHOL_DRINKS_PER_WEEK,
  calorieRestrictionName, transFatIntakeName, refinedGrainName, wholeGrainName,
  legumesName, processedMeatName, poultryName, redMeatName, vegetablesName,
  fruitName, waterIntakeName, sugarySugar, artificiallySugar,
  cardioName, strengthTrainingName, actualSleepHoursName, sleepQualityName,
  activitiesSaunaBathingName, stressQualityName, calciumName, fishOilOmega3Name,
  matchaGreenTeaName, smoking_frequencyName
} from '../archive/CalculatorName'

import {
  aboutMeAlcoholModelName, aboutMeAgeModelName, aboutMeGenderModelName, aboutMeSmokingStatusModelName,
  aboutMeSmokingFrequencyModelName, dietCalorieRestrictionModelName, dietFatTransModelName,
  dietGrainRefinedModelName, dietGrainUnrefinedModelName, dietLegumesModelName, dietMeatProcessedModelName,
  dietMeatUnprocessedModelName, dietMeatPoultryModelName, dietFruitsAndVeggiesModelName, dietWaterModelName,
  dietRefinedSugarModelName, dietArtificialSweetenerModelName, exerciseCardioModelName, exerciseStrengthTrainingModelName,
  mySleepSleepDurationModelName, mySleepSleepQualityModelName, exerciseSaunaFrequencyModelName, nodeStressQualityModelName,
  supplementsCalciumModelName, supplementsFishOilOmega3ModelName, supplementsGreenTeaModelName, smoking_frequencyModelName
} from '../archive/CalculatorModelName'

import {
  genderOptions, cigarettesOptions, calorieIntakeOptions, 
  sweetTimeOptions, sleepQualityOptions, stressQualityOptions, 
  calciumOptions, fishOilOmega3Options,  matchaGreenTeaOptions
} from '../archive/CalculatorOptions'

import {
  genderLabel, dateOfBirthLabel, cigarettesUseLabel, formerCigarettesYearsLabel, currentCigarettesYearsLabel, 
  alcoholDrinksPerWeekLabel, calorieRestrictionLabel, transFatIntakeLabel, refinedGrainLabel, wholeGrainLabel, 
  legumesLabel, processedMeatLabel, poultryLabel, redMeatLabel, vegetablesLabel, fruitLabel, waterIntakeLabel, 
  sugarySugarLabel, artificiallySugarLabel, cardioLabel, strengthTrainingLabel, actualSleepHoursLabel, 
  activitiesSaunaBathingLabel, stressQualityLabel, calciumLabel, fishOilOmega3Label, matchaGreenTeaLabel,
  smoking_frequency
} from '../archive/CalculatorLabel'
import { getAge } from '../actions'
import { Alert } from 'react-bootstrap'

// const [aboutMe, setAboutMe] = useState({
//   [DATE_OF_BIRTH]: null,
//   [AGE]: null,
//   [ALCOHOL_DRINKS_PER_WEEK]: null,
//   [GENDER]: null,
//   [CIGARETTES_USE]: null,
//   [FORMER_CIGARETTES_YEARS]: null,
//   [CURRENT_CIGARETTES_YEARS]: null,
//   [smoking_frequencyName]: null,
//   smoking_status: null,
// });


export default function AboutMe({dataState, setDataState, errors, setErrors, maxDate}) {
  const handleInputChange = (name, value) => {
    let dbValue = {...dataState, [name]: value}
    if(name === 'dob'){
      const dob = new Date(value);
      const today = new Date();
      let age = today.getFullYear() - dob.getFullYear();
      dbValue = {...dbValue, [AGE]: age}
    }

    if(name === CIGARETTES_USE) {
      dbValue = {...dbValue, 'smoking_duration': null, [smoking_frequencyName]: null}
      setErrors({...errors, [smoking_frequencyName]: null, 'smoking_duration': null})
    }
    setDataState(dbValue)
    
  }
  return (
    <div>
      {/* <p>
        {
          `${dataState[CIGARETTES_USE]}, ${dataState[FORMER_CIGARETTES_YEARS]}, ${dataState[CURRENT_CIGARETTES_YEARS]}`
        }
      </p> */}
        <small class="form-text">About Me</small>
        <DateInput label={dateOfBirthLabel} onChange={handleInputChange} value={dataState[DATE_OF_BIRTH]} name={DATE_OF_BIRTH} error={errors[DATE_OF_BIRTH]} max={maxDate} />
        <Menu label={genderLabel} onChange={handleInputChange} options={genderOptions} value={dataState[GENDER]} name={GENDER} error={errors[GENDER]}/>
        <InputSingle label={alcoholDrinksPerWeekLabel} name={ALCOHOL_DRINKS_PER_WEEK} onChange={handleInputChange} value={dataState[ALCOHOL_DRINKS_PER_WEEK]} options={['drinks/week']} error={errors[ALCOHOL_DRINKS_PER_WEEK]}/>

        <Menu label={cigarettesUseLabel} options={cigarettesOptions} name={CIGARETTES_USE} onChange={handleInputChange} value={dataState[CIGARETTES_USE]} error={errors[CIGARETTES_USE]}/>
        
        {(dataState[CIGARETTES_USE] === '1' || dataState[CIGARETTES_USE] === 1) && 
        <InputSingle label={formerCigarettesYearsLabel} name={FORMER_CIGARETTES_YEARS} onChange={handleInputChange} value={dataState[FORMER_CIGARETTES_YEARS]} error={errors[FORMER_CIGARETTES_YEARS]}/>

        }
        
        {(dataState[CIGARETTES_USE] === '2' || dataState[CIGARETTES_USE] === 2) && 
        <InputSingle label={currentCigarettesYearsLabel} name={CURRENT_CIGARETTES_YEARS} onChange={handleInputChange} value={dataState[CURRENT_CIGARETTES_YEARS]} error={errors[CURRENT_CIGARETTES_YEARS]}/>
        }
        {(dataState[CIGARETTES_USE] === '2' || dataState[CIGARETTES_USE] === 2) && 
        <InputSingle label={smoking_frequency} name={smoking_frequencyName} onChange={handleInputChange} value={dataState[smoking_frequencyName]}  error={errors[smoking_frequencyName]}/>
        }
    </div>
  )
}
