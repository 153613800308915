import React, { useRef } from 'react';

function CustomEmailValidation({ value, onChange, ...props }) {
  const emailRef = useRef(null);

  const handleChange = () => {
    const emailInput = emailRef.current;
    const email = emailInput.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Email validation regex

    if (!emailRegex.test(email)) {
      emailInput.setCustomValidity(
        'Please enter a valid email address (e.g., name@example.domain).'
      );
    } else {
      emailInput.setCustomValidity('');
    }

    emailInput.reportValidity();
  };

  return (
    <input
      type='email'
      ref={emailRef}
      value={value}
      onChange={(e) => {
        onChange(e);
        handleChange();
      }}
      required
      {...props}
    />
  );
}

export default CustomEmailValidation;
