import axios from 'axios'
import { getCookie } from "../../../utils/cookie";

const csrfToken = getCookie("csrftoken");
const baseUrlAWS = process.env.REACT_APP_baseUrl;
//const baseUrlAWS = process.env.REACT_APP_localBaseUrlAWS;
const baseUrlUser = `${baseUrlAWS}api/users`


//const baseUrlUser = `${baseUrlAWS}/api/users`;
//change baseURLAWS to aws link
//get actual user from localStorage



export const sendData = async (info, userInfo) => {
    // console.log(userInfo)
    const data = {...info, 'user': userInfo}
    try {
        const response = await axios.post(`${baseUrlAWS}api/plan/post/`, data, {
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
        },
        });
        
        console.log(response.data);
        
    } catch (error) {
        console.log(error); 
    }
}

//fetch user data
export const fetchUserData = (userId) => {
    return axios.get(
        `${baseUrlUser}/get-user-data/${userId}`,
        {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(response => response.data)
        .catch(error => {
            if (error.response && error.response.status === 500) {
                return null
            } else {
                console.error('Error fetching form data:', error)
                throw error
            }
        })

}
