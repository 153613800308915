import React from 'react'
import Menu from '../../../components/questionnaireElement/Menu'
import InputSingle from '../../../components/questionnaireElement/InputSingle'
import CalculatorInputSingle from '../../../components/questionnaireElement/CalculatorInputSingle'
import InputMulti from '../../../components/questionnaireElement/InputMulti'
import DateInput from '../../../components/questionnaireElement/DateInput'
import SupplementWarning from '../../../components/SupplementWarning'
import {
  DATE_OF_BIRTH, GENDER, CIGARETTES_USE, AGE,
  FORMER_CIGARETTES_YEARS, CURRENT_CIGARETTES_YEARS, ALCOHOL_DRINKS_PER_WEEK,
  calorieRestrictionName, transFatIntakeName, refinedGrainName, wholeGrainName,
  legumesName, processedMeatName, poultryName, redMeatName, vegetablesName,
  fruitName, waterIntakeName, sugarySugar, artificiallySugar,
  cardioName, strengthTrainingName, actualSleepHoursName, sleepQualityName,
  activitiesSaunaBathingName, stressQualityName, calciumName, fishOilOmega3Name,
  matchaGreenTeaName
} from '../archive/CalculatorName'

import {
  aboutMeAlcoholModelName, aboutMeAgeModelName, aboutMeGenderModelName, aboutMeSmokingStatusModelName,
  aboutMeSmokingFrequencyModelName, dietCalorieRestrictionModelName, dietFatTransModelName,
  dietGrainRefinedModelName, dietGrainUnrefinedModelName, dietLegumesModelName, dietMeatProcessedModelName,
  dietMeatUnprocessedModelName, dietMeatPoultryModelName, dietFruitsAndVeggiesModelName, dietWaterModelName,
  dietRefinedSugarModelName, dietArtificialSweetenerModelName, exerciseCardioModelName, exerciseStrengthTrainingModelName,
  mySleepSleepDurationModelName, mySleepSleepQualityModelName, exerciseSaunaFrequencyModelName, nodeStressQualityModelName,
  supplementsCalciumModelName, supplementsFishOilOmega3ModelName, supplementsGreenTeaModelName
} from '../archive/CalculatorModelName'

import {
  genderOptions, cigarettesOptions, calorieIntakeOptions, 
  sweetTimeOptions, sleepQualityOptions, stressQualityOptions, 
  calciumOptions, fishOilOmega3Options,  matchaGreenTeaOptions
} from '../archive/CalculatorOptions'

import {
  genderLabel, dateOfBirthLabel, cigarettesUseLabel, formerCigarettesYearsLabel, currentCigarettesYearsLabel, 
  alcoholDrinksPerWeekLabel, calorieRestrictionLabel, transFatIntakeLabel, refinedGrainLabel, wholeGrainLabel, 
  legumesLabel, processedMeatLabel, poultryLabel, redMeatLabel, vegetablesLabel, fruitLabel, waterIntakeLabel, 
  sugarySugarLabel, artificiallySugarLabel, cardioLabel, strengthTrainingLabel, actualSleepHoursLabel, 
  activitiesSaunaBathingLabel, stressQualityLabel, calciumLabel, fishOilOmega3Label, matchaGreenTeaLabel, sleepQualityLabel
} from '../archive/CalculatorLabel'

export default function Supplements({ dataState, setDataState, onChangeUnit, onChangeUnitDefault, greenTeaUnit, errors, limit, setLimit }) {
  const handleInputChange = (name, value) => {
      const dbValue = { ...dataState, [name]: value };
      setDataState(dbValue);
  };

  const greenTeaInstruction = '1 oz ~ 29.57 ml';
  const calciumInstruction = 'Recommended 1,000-1,200 mg/day. Do not exceed 2,000 mg/day';
  const fishOilOmega3Instruction = 'Do not exceed 5000 mg/day';

  return (
      <div>
          <small className="form-text">Supplements</small>
          <p>Please input the amount of the supplements you consume per day</p>
          <SupplementWarning />
          <InputSingle label={calciumLabel} name={calciumName} onChange={handleInputChange} value={dataState[calciumName]} options={['mg/day']} instruction={calciumInstruction} error={errors[calciumName]} limit={limit[calciumName]}/>
          <InputSingle label={fishOilOmega3Label} name={fishOilOmega3Name} onChange={handleInputChange} value={dataState[fishOilOmega3Name]} options={['mg/day']} instruction={fishOilOmega3Instruction} error={errors[fishOilOmega3Name]} limit={limit[fishOilOmega3Name]}/>
          <CalculatorInputSingle
              label={matchaGreenTeaLabel}
              name={matchaGreenTeaName}
              onChange={handleInputChange}
              value={dataState[matchaGreenTeaName]}
              // options={['ml/day', 'oz/day']}
              options={['oz/day']}
              instruction={greenTeaInstruction}
              onChangeUnit={onChangeUnit}
              onChangeUnitDefault={onChangeUnitDefault}
              currentUnit={greenTeaUnit} // Pass the current unit as prop only for Green Tea
              error={errors[matchaGreenTeaName]}
              limit={limit[matchaGreenTeaName]}
          />
      </div>
  );
}
