import axios from 'axios';

const baseUrl = process.env.REACT_APP_baseUrl
//const baseUrl = process.env.REACT_APP_localBaseUrlAWS;
const baseModelUrl = process.env.REACT_APP_baseModelUrl;
const modelUrl = `${baseModelUrl}lifespan_calculator/`;

// const modelUrl = `${baseUrl}api/calculator/lifespan_calculator/`;
const optimizationModelUrl = `${baseModelUrl}optimization_model/`;

const baseUrlAWS = baseUrl+"api/calculator"
const baseURLDB = baseUrl+"api/lifestyle"
const baseUrlUser = baseUrl+"api/users"
export const getAge = (dateObBirth) => {
    const dob = new Date(dateObBirth);
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    // alert(age)
    return age;
}

export const fetchPredictData = async (dataToSend) => {
  return axios.post(
    optimizationModelUrl,
    dataToSend,
  )
  .then(response => response.data)
  .catch(error => {
    console.error('Error submitting form data:', error);
    throw error;
  });
};

export const fetchDBData = async (dataToSend) => {
  return axios.post(
    `${baseURLDB}/add-data-from-calculator/`,
    dataToSend,
  )
  .then(response => response.data)
  .catch(error => {
    console.error('Error submitting form data:', error);
    throw error;
  });
};
export const fetchProbData = async (dataToSend) => {
    return axios.post(
      `${baseUrlAWS}/send-calculator-information/`,
      dataToSend,
    )
    .then(response => response.data)
    .catch(error => {
      console.error('Error submitting form data:', error);
      throw error;
    });
  };

export const updateProbData = async (dataToSend) => {
  return axios.put(
    `${baseUrlAWS}/send-calculator-information/`,
    dataToSend,
  )
  .then(response => response.data)
  .catch(error => {
    console.error('Error submitting form data:', error);
    throw error;
  });
};

export const fetchUserInfo = async (userId) => {
  // console.log(userId)
  return await axios.get(
    `${baseUrlAWS}/get-calculator-result/${userId}`,
  )
  .then(response => response.data)
  .catch(error => {
    console.error('Error submitting form data:', error);
    throw error;
  }); 
}

export const getScoreFromLocal = async (userId) => {
  const userIdWithoutDash = userId.replace(/-/g, '');
  // alert(userIdWithoutDash)
  return await axios.post(
    `${modelUrl}`,
    {"user_id": userIdWithoutDash},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ). then(response => response.data)
  .catch(error => {
    console.error('Error submitting form data:', error);
    throw error;
  });
}

export const fetchUserData = async (userId) => {
  return await axios.get(
      `${baseUrlUser}/get-user-data/${userId}`,
      {
          headers: {
              "Content-Type": "application/json",
          },
      })
      .then(
          response => response.data
      )
      .catch(error => {
          if (error.response && error.response.status === 500) {
              return null
          } else {
              console.error('Error fetching form data:', error)
              throw error
          }
      })
}
