import React, { useState, useMemo, useEffect } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import axios from 'axios'
import {getCookie} from "../../../utils/cookie"
import { fetchScores } from "../action";

export default function QoLScale() {

  const [submittedToday, setSubmittedToday] = useState(true)

  useEffect(() => {
    const fetchTestScores = async (userId) => {
        try {
            const data = await fetchScores(userId)

            if (data.filter(data => data.testId === 'qol').length > 0) {
                const testDate = new Date(data.filter(data => data.testId === 'qol')[0].submission_date.substring(0,10).split('-')).toUTCString().substring(0,16)
                const currDate = new Date().toUTCString().substring(0,16)

                if (testDate === currDate) {
                  setSubmittedToday(true)
                } else {
                  setSubmittedToday(false)
                }
            } else {
              setSubmittedToday(false)
            }

        } catch (error) {
            console.error('Error fetching test scores', error)
        }
    }
    fetchTestScores(userId)
  }, [])

  const baseUrl = process.env.REACT_APP_baseUrl;
  const baseUrlAWS =`${baseUrl}api/myprogress`

  const userId = useMemo(() => {
    let user = localStorage.getItem("mysUser");
      user = user
        ? JSON.parse(user)
        : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
    return user.userId;
  })

 const questions = [
   '1. Material comforts home, food, conveniences, financial security',
   '2. Health - being physically fit and vigorous',
   '3. Relationships with parents, siblings & other relatives - communicating, visiting, helping',
   '4. Having and rearing children',
   '5. Close relationships with spouse or significant other',
   '6. Close friends',
   '7. Helping and encouraging others, volunteering, giving advice',
   '8. Participating in organizations and public affairs',
   '9. Learning - attending school, improving understanding, getting additional knowledge',
   '10. Understanding yourself - knowing your assets and limitations - knowing what life is about',
   '11. Work - job or at home',
   '12. Expressing yourself creatively',
   '13. Socializing - meeting other people, doing things, parties, etc.',
   '14. Reading, listening to music, or observing entertainment',
   '15. Participating in active recreation',
   '16. Independence, doing for yourself',
 ];

 const categories = [
   { name: 'Delighted', value: 7 },
   { name: 'Pleased', value: 6 },
   { name: 'Mostly Satisfied', value: 5 },
   { name: 'Mixed', value: 4 },
   { name: 'Mostly Dissatisfied', value: 3 },
   { name: 'Unhappy', value: 2 },
   { name: 'Terrible', value: 1 },
 ];

 const [selectedValues, setSelectedValues] = useState(Array(questions.length).fill(''));
 const [showScore, setShowScore] = useState(false);
 const [totalScore, setTotalScore] = useState(0);

 const handleAnswerChange = (questionIndex, categoryIndex) => {
   const updatedValues = [...selectedValues];
   updatedValues[questionIndex] = categoryIndex;
   setSelectedValues(updatedValues);
 };

 const calculateTotalScore = () => {
   let score = 0;
   let unansweredQ = false
   selectedValues.forEach((selectedValue, questionIndex) => {
     const category = categories.find((cat) => cat.name === selectedValue);
     if (category) {
       score += category.value;
     } else {
        unansweredQ = true
     }
   });

   if (unansweredQ) {
    return -1
   } else {
    return score
   }
 };

 const handleSubmit = () => {
   const score = calculateTotalScore();

   if (score === -1) {
      return
   }

   setTotalScore(score);
   setShowScore(true);

   let testRes = {}
   testRes["user"] = userId
   testRes["testId"] = "qol"
   testRes["score"] = score

   submitData(testRes)
   console.log(testRes)
 };

  // call API to submit the form data 
  const submitData = (testData) => {
  const csrfToken = getCookie("csrftoken")
  console.log(JSON.stringify(testData))
  axios.post(
      `${baseUrlAWS}/create-progress-test-score-entry/`,
      testData,
      {
          headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
          },
      })
      .then(response => {
          console.log(response)
      })
      .catch(error => {
          if (error.response && error.response.status === 500) {
              return null
          } else {
              console.error('Error fetching form data:', error)
              throw error
          }
      })
  }

 return (
   <View style={{ flex: 1, padding: 20 }}>
     <Text style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 10, textAlign: 'center', marginTop: 50 }}>
       Quality Of Life Scale
     </Text>
     <Text style={{ marginBottom: 10, fontWeight: 'bold', textAlign: 'left' }}>
       Below are a number of statements about quality of life. Please answer each item even if you do not
       currently participate in an activity or have a relationship. You can be satisfied or dissatisfied with not
       doing the activity or having the relationship.
     </Text>
     <View style={{ flexDirection: 'row', marginBottom: 14 }}>
       <Text style={{ width: '40%' }} />
       {categories.map((category, categoryIndex) => (
         <Text key={categoryIndex} style={{ width: '8.6%', textAlign: 'center' }}>
           {category.name}
         </Text>
       ))}
     </View>
     {questions.map((question, questionIndex) => (
       <View
         key={questionIndex}
         style={{
           flexDirection: 'row',
           marginBottom: 14,
           backgroundColor: questionIndex % 2 === 1 ? '#f0f0f0' : '#ffffff',
           minHeight: 50,
           paddingVertical: 8,
           paddingHorizontal: 10,
           borderRadius: 5,
         }}
       >
         <Text style={{ width: '40%' }}>{question}</Text>
         <View style={{ flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
           {categories.map((category, categoryIndex) => {
             const isSelected = selectedValues[questionIndex] === category.name;
             return (
               <TouchableOpacity
                 key={categoryIndex}
                 style={{
                   alignItems: 'center',
                   justifyContent: 'center',
                   borderRadius: 50,
                   width: 20,
                   height: 20,
                   borderWidth: 1,
                   borderColor: '#000000',
                   backgroundColor: isSelected ? '#c0c0c0' : '#ffffff',
                   marginLeft: categoryIndex === 0 ? 0 : '8.6%',
                 }}
                 onPress={() => handleAnswerChange(questionIndex, category.name)}
               >
                 {isSelected && (
                   <View
                     style={{
                       width: 12,
                       height: 12,
                       borderRadius: 6,
                       backgroundColor: '#000000',
                     }}
                   />
                 )}
               </TouchableOpacity>
             );
           })}
         </View>
       </View>
     ))}
     {(!showScore && !submittedToday) && (
       <TouchableOpacity
         style={{
           alignItems: 'center',
           backgroundColor: '#ffffff',
           borderColor: '#000000',
           borderWidth: 1,
           borderRadius: 5,
           paddingVertical: 5,
           paddingHorizontal: 5,
           width: '10%',
           alignSelf: 'center',
           marginTop: 10,
         }}
         onPress={handleSubmit}
       >
         <Text style={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: 12 }}>Submit</Text>
       </TouchableOpacity>
     )}
     {(showScore || submittedToday) && (
       <TouchableOpacity
         style={{
           alignItems: 'center',
           backgroundColor: '#ffffff',
           borderColor: '#000000',
           borderWidth: 1,
           borderRadius: 5,
           paddingVertical: 5,
           paddingHorizontal: 5,
           width: '10%',
           alignSelf: 'center',
           marginTop: 10,
         }}
       >
         <Text style={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: 12 }}>Already Submitted Today</Text>
       </TouchableOpacity>
     )}
     {showScore && (
       <>
         <Text style={{ marginTop: 10, textAlign: 'center', fontWeight: 'bold' }}>
           Total Score: {totalScore} / 122 possible points
         </Text>
       </>
     )}
   </View>
 )
}
