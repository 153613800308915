const genderOptions = ['Male', 'Female'];
const cigarettesOptions = ["Never used", "Former user", "Current user", "I decline to answer", "I don't know"];
const calorieIntakeOptions = ["No", "Yes"];

const sweetTimeOptions = ["Day"];
const sleepQualityOptions = ["Poor - I frequently wake up during the night and do not feel rested in the morning.",
                            "Fair - I occasionally wake up during the night but generally feel somewhat rested in the morning.",
                            "Excellent - I sleep through the night without interruptions and wake up feeling refreshed."];
const stressQualityOptions = ["Low - I rarely feel stressed and can manage daily challenges with ease.",
                            "Moderate - I experience stress occasionally but can generally cope with it.",
                            "High - I frequently feel overwhelmed by stress and find it difficult to cope."];

const calciumOptions = ["mg/day", "g/day"];
const fishOilOmega3Options = ["g/day", "mg/day"];
const matchaGreenTeaOptions = ["ml/day", "oz/day"];

export { 
    genderOptions, cigarettesOptions, calorieIntakeOptions, 
    sweetTimeOptions, sleepQualityOptions, stressQualityOptions, 
    calciumOptions, fishOilOmega3Options,  matchaGreenTeaOptions
};