// src/SignUp.js
import { signUp } from "./auth";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./CognitoUI.css";
import TermsAndConditions from "./TermsAndConditions";
import CustomEmailValidation from "./CustomEmailValidation";

function CognitoSignup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);

  const [isPendingRegister, setIsPendingRegister] = useState(false);

  // Added new state for Date of Birth
  const [dob, setDob] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password2, setPassword2] = useState("");
  const [signupError, setSignupError] = useState("");
  const [displayPassword, setDisplayPassword] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [allFields, setAllFields] = useState(false);
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);

  let navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invite_id = queryParams.get("invite_id");
  const orgtype = queryParams.get("orgtype");

  // New function to calculate age from date of birth
  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();

    // If the birth month and day haven't passed in the current year, subtract 1 from age
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() - 1 < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleScroll = (e) => {
    const atBottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
    setHasScrolledToBottom(atBottom);
  };

  const handleAcceptTerms = async () => {
    if (hasScrolledToBottom) {
      setHasAgreedToTerms(true);
      setIsTermsModalOpen(false);
      setSignupError("");
      if (isPendingRegister) {
        const signUpSuccess = await executeSignUp();
        console.log("sign up success status: " + signUpSuccess);
        if (signUpSuccess) {
          navigateToConfirm();
        } else {
          setSignupError(
            "Failed to sign up. Please refresh and try again, make sure you use an unregistered email."
          );
        }
      }
    } else {
      setSignupError(
        "Please scroll to the bottom of the terms and conditions first."
      );
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const signUpUser = async () => {
    setSignupError("");
    if (!firstName || !lastName || !dob || !email || !password || !password2) {
      setSignupError("Please complete all fields.");
      return;
    }

    // Check if age is under 13 before submitting
    const age = calculateAge(dob);
    if (age < 13) {
      setSignupError("You must be at least 13 years old to sign up.");
      return;
    }

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])[A-Za-z\d\^\$\*\.\[\]\{\}\(\)\?"!@#%&\/\\,><':;|_~`+=\s]{8,}$/;
    if (!passwordRegex.test(password)) {
      setSignupError(
        "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long."
      );
      return;
    }
    if (password !== password2) {
      setSignupError("Passwords do not match.");
      return;
    }

    try {
      await signUp(email, password, firstName, lastName);
      return true;
    } catch (err) {
      console.log("Error during sign up:", err);
      if (err.code === "UsernameExistsException") {
        setSignupError("An account with this email already exists.");
        return false;
      } else {
        setSignupError(err.message);
        return false;
      }
    }
  };

  const navigateToConfirm = () => {
    sessionStorage.setItem("recentlySignedUpUsername", email);
    const destination = invite_id
      ? `/confirm-signup?invite_id=${invite_id}&orgtype=${orgtype}`
      : "/confirm-signup";
    navigate(destination);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!hasAgreedToTerms) {
      setIsTermsModalOpen(true);
      setIsPendingRegister(true);
      setSignupError("Please accept the terms and conditions first.");
      return;
    }
  };

  const executeSignUp = async () => {
    console.log("execute sign up");
    try {
      const resp = await signUpUser();
      return resp;
    } catch (err) {
      if (err.code === "UsernameExistsException") {
        setSignupError("An account with this email already exists.");
        return false;
      } else {
        setSignupError(err.message);
        return false;
      }
    }
  };

  const handlePasswordDisplay = () => {
    if (displayPassword === true) {
      setDisplayPassword(false);
      setPasswordType("password");
    } else if (displayPassword === false) {
      setDisplayPassword(true);
      setPasswordType("text");
    }
  };

  // **Added Section: Calculate Yesterday's Date**
  const today = new Date();
  today.setDate(today.getDate() - 1); // Subtract one day to exclude today
  const maxDate = today.toISOString().split("T")[0]; // Format the date to YYYY-MM-DD

  return (
    <div>
      <br />
      <br />
      <br />
      <br />

      <div className="cognito_login_box">
        <form onSubmit={handleSignUp}>
          <h4 className="cognito_login_title">Register</h4>
          <label className="cognito_login_label">First name:</label>
          <div>
            <input
              className="cognito_lable_box"
              type="text"
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              value={firstName}
            />
          </div>
          <label className="cognito_login_label">Last name:</label>
          <div>
            <input
              className="cognito_lable_box"
              type="text"
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              value={lastName}
            />
          </div>
          <label className="cognito_login_label">Date of Birth:</label>{" "}
          {/* Added Date of Birth field */}
          <div>
            <input
              className="cognito_lable_box"
              type="date"
              onChange={(e) => {
                setDob(e.target.value);
              }}
              value={dob}
              max={maxDate} // **Added Attribute**
              required
            />
          </div>
          <label className="cognito_login_label">Email:</label>
          <div>
            <CustomEmailValidation
              className="cognito_lable_box"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <label className="cognito_login_label">Password:</label>
          <div>
            <input
              className="cognito_lable_box"
              type={passwordType}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
            />
          </div>
          <label className="cognito_login_label">Confirm password:</label>
          <div>
            <input
              className="cognito_lable_box"
              type={passwordType}
              onChange={(e) => {
                setPassword2(e.target.value);
              }}
              value={password2}
            />
          </div>
          <div>
            <input
              style={{ marginRight: "10px", marginTop: "10px" }}
              type="checkbox"
              onChange={() => {
                handlePasswordDisplay();
              }}
            />
            {!displayPassword && <span>Show password</span>}
            {displayPassword && <span>Hide password</span>}
          </div>
          <div>
            <p className="cognito_login_error">{signupError}</p>
            <p className="cognito_login_note">
              Note: the password must contain at least one capital letter, one
              lowercase letter, one special character, and a number. It must
              also be at least 8 characters long.
            </p>
            <p className="cognito_login_note">
              Note: Current models are not well calibrated for estimating life
              expectancy of individuals below 18 years of age.
            </p>
          </div>
          <div>
            <button className="cognito_login_submit">Submit</button>
          </div>
        </form>
      </div>
      {isTermsModalOpen && (
        <div className="modal">
          <div className="modal-content" onScroll={handleScroll}>
            <span
              className="close-button"
              onClick={() => setIsTermsModalOpen(false)}
            >
              &times;
            </span>
            <TermsAndConditions
              setHasScrolledToBottom={setHasScrolledToBottom}
            />
            <button onClick={handleAcceptTerms} disabled={!hasScrolledToBottom}>
              Accept
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CognitoSignup;
