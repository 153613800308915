import { useState } from "react"
import { forgotPassword } from "./auth"
import {useNavigate, Link } from "react-router-dom"

export default function ForgotPassword() {
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)
  //let navigate = useNavigate()

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/
      );
  };



  const handleSubmit = async (e) => {
    e.preventDefault()
    setError("")

    if (!validateEmail(email)) {
        setError("Please enter a valid email address.");
        return;
      }

      try {
        await forgotPassword(email);
        console.log("Please check you email for Password Reset Code");
        setSuccess(true);
      } catch (err) {
        console.error("Error during forgot password process:", err);
        if (err.code === 'UserNotFoundException') {
          setError("Email does not exist in our system.");
        } else {
          setError(err.message);
        }
      }
  }
  let navigate = useNavigate()

  if (success) {
    navigate("/reset-password");
  }

  return (
    
    <div>
        <br />
        <br />
        <br />
        <br />
        <div className="cognito_login_box">
            <form onSubmit={handleSubmit}>
                <h4 className="cognito_login_title">Forgot Password</h4>
                <label className="cognito_login_label">Email:</label>
                <div >

                <input
                className="cognito_lable_box"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}

                />
                </div>
                <button className="cognito_login_submit">Submit</button>
            </form>
            {error && <p>{error}</p>}
        </div>

    </div>
  )
}