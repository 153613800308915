import "./Footer.css"
const Footer=()=>{
    return <div className="footer">
        <div style={{fontSize:"18px",fontWeight:"700"}}>Contact us</div>
        <div style={{marginBottom:"4px"}}>For Information: <a href="mailto:info@myyouthspan.com">info@myyouthspan.com</a></div>
        <div style={{marginBottom:"16px"}}>For Application Support: <a href="mailto:support@myyouthspan.com">support@myyouthspan.com</a></div>
        <div className="seperator"></div>
        <div>Copyright © METY Technology, Inc. 2024</div>
        </div>
}

export default Footer;