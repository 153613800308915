import React, { useEffect } from 'react'

export default function InputTime({label, options, disabled, instruction, name, onChange, onChangeUnit, onChangeUnitDefault, value, error}) {
    if(!options){
        options = [];
    }

    useEffect(() => {
        if (options.length > 1 && onChangeUnitDefault && typeof onChangeUnitDefault === 'function') {
            onChangeUnitDefault(name, options[0]);
        }
    }, [])

    const handleChange = (event) => {
        onChange(name, event.target.value);
    };

    const handleUnitChange = (event) => {
        if(event.target.value !== options[0]){
            onChangeUnitDefault(name, options[0])
        }
        onChangeUnit(name, event.target.value);
    };
  return (
    // <div class="form-group row mb-1">
        <div class="col">
        <div class="d-flex justify-content-center">
            <label>{label}</label>
            </div>
            <div class="input-group">
            <input type="time" class="form-control" placeholder={label}  disabled={disabled} onChange={handleChange} value={value} defaultValue={0}/>
            {
            options.length > 1 ? (
                <div class="input-group-append">
                    <select class="form-control" onChange={handleUnitChange}>     
                        {options.map((option, index) => (
                            <option value={option} id={index}>{option}</option>                         
                        ))}
                    </select>
                </div>
            ) : options.length === 1 ?(
                <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2" value={options[0]}>{options[0]}</span>
                </div>
            ) : null
            }
        </div>
        {error && <p style={{color: 'red', fontSize: '16px', marginBottom: '0'}}>{error}</p>}
        {instruction &&
            <div class="d-flex justify-content-center">
                <small class="text-muted">{instruction}</small>
            </div>
        }
        </div>
    // </div>
  )
}
