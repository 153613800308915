const DATE_OF_BIRTH = "dob";      
const AGE = 'age';                          
const GENDER = "gender";                                    
const CIGARETTES_USE = "smoking_status";                    
const FORMER_CIGARETTES_YEARS = "smoking_duration";          
const CURRENT_CIGARETTES_YEARS = "smoking_duration";         
const ALCOHOL_DRINKS_PER_WEEK = "alcohol";   
const calorieRestrictionName = "calorie_restriction";        
const transFatIntakeName = "fat_trans";                     
const refinedGrainName = "grain_refined";                    
const wholeGrainName = "grain_unrefined";                        
const legumesName = "legumes";                              
const processedMeatName = "meat_processed";                  
const poultryName = "meat_poultry";                              
const redMeatName = "meat_unprocessed";                              
const vegetablesName = "vegetables";                        
const fruitName = "fruit";             
const fruitAndVegetableName = 'fruits_and_veggies';                     
const waterIntakeName = "water";                            
const sugarySugar = "refined_sugar";                        
const artificiallySugar = "artificial_sweetener";           
const cardioName = 'cardio';                                
const strengthTrainingName = "strength_training";              
const actualSleepHoursName = "sleep_duration";              
const sleepQualityName = "sleep_quality_score_lifestyle";                    
const activitiesSaunaBathingName = "sauna_frequency";           
const stressQualityName = "stress_level_score";                  
const calciumName = "calcium";                               
const fishOilOmega3Name = "fish_oil_omega_3";                 
const matchaGreenTeaName = "green_tea";
const smoking_frequencyName = 'smoking_frequency';     
export {
    DATE_OF_BIRTH, GENDER, CIGARETTES_USE, AGE,
    FORMER_CIGARETTES_YEARS, CURRENT_CIGARETTES_YEARS, ALCOHOL_DRINKS_PER_WEEK,
    calorieRestrictionName, transFatIntakeName, refinedGrainName, wholeGrainName,
    legumesName, processedMeatName, poultryName, redMeatName, vegetablesName,
    fruitName, waterIntakeName, sugarySugar, artificiallySugar,
    cardioName, strengthTrainingName, actualSleepHoursName, sleepQualityName,
    activitiesSaunaBathingName, stressQualityName, calciumName, fishOilOmega3Name,
    matchaGreenTeaName, smoking_frequencyName, fruitAndVegetableName
};
