import React from 'react'
import { useEffect, useState } from 'react'
import InputCheckBox from '../../../components/questionnaireElement/InputCheckBox'
import { familyMedicalHistoryLabel } from './Lables/FamilyMedicalLabels';
import { familyMedicalHistoryNames } from './NameTable/FamilyMedicalName';
import { familyMedicalHistoryOptions } from './OptionsTable/FamilyMedicalOptions';
const formDataKey = 'IntakeForm'; 
export default function FamilyMedical({setHasChanges, dataState, setDataState, errors}) {
  const [formData, setformData] = useState(sessionStorage.getItem(formDataKey) || {});
  useEffect(() => {
    const savedData = sessionStorage.getItem(formDataKey);
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setformData(parsedData);
    }
  }, []);
  const handleInputChange = (name, value) => {
    setHasChanges(true);
    
    let newValue = {...dataState, [name]:value}
    setDataState(newValue)
  };
  return (
    <form>
        <div class='inlineContainer'>
          <small class="form-text">My Family Medical History</small>
        </div>
        <InputCheckBox label={familyMedicalHistoryLabel} name={familyMedicalHistoryNames}
         onChange={handleInputChange} options={familyMedicalHistoryOptions} value={dataState}/>
    </form>
  )
}
