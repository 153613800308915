import React, {useState, useMemo, useEffect} from "react";
import axios from "axios";
import {getCookie} from "../../../utils/cookie"
import { fetchScores } from "../action";

//each index corresponds to the score of the question. Initialized at 0.
const current = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
const baseUrl = process.env.REACT_APP_baseUrl;
const baseUrlAWS =`${baseUrl}api/myprogress`

function HappinessTest() {

  const [submittedToday, setSubmittedToday] = useState(true)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    const fetchTestScores = async (userId) => {
        try {
            const data = await fetchScores(userId)

            if (data.filter(data => data.testId === 'happiness').length > 0) {
                const testDate = new Date(data.filter(data => data.testId === 'happiness')[0].submission_date.substring(0,10).split('-')).toUTCString().substring(0,16)
                const currDate = new Date().toUTCString().substring(0,16)

                if (testDate === currDate) {
                  setSubmittedToday(true)
                } else {
                  setSubmittedToday(false)
                }
            } else {
              setSubmittedToday(false)
            }

        } catch (error) {
            console.error('Error fetching test scores', error)
        }
    }
    fetchTestScores(userId)
  }, [])

  const userId = useMemo(() => {
    let user = localStorage.getItem("mysUser");
      user = user
        ? JSON.parse(user)
        : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
    return user.userId;
  })

  //used to calculate total value
  const [total, setTotal] = useState(0); 
  const [result, setResult] = useState(''); 

  //array of questions
  const questions = ["1. I don't feel particularly pleased with the way I am.", "2. I am intensely interested in other people.",
  "3. I feel that life is very rewarding.",  "4. I have very warm feelings towards almost everyone.", "5. I rarely wake up feeling rested.",
 "6. I am not particularly optimistic about the future", "7. I find most things amusing.", "8. I am always committed and involved.", 
 "9. Life is good.", "10. I do not think that the world is a good place.", "11. I laugh a lot.", "12. I am well satisfied about everything in my life.",
 "13. I don't think I look attractive.", "14. There is a gap between what I would like to do and what I have done.", "15. I am very happy.", 
 "16. I find beauty in some things.", "17. I always have a cheerful effect on others.", "18. I can fit in (find time for) everything I want to.",
 "19. I feel that I am not especially in control of my life.", "20. I feel able to take anything on.", "21. I feel fully mentally alert.", 
 "22. I often experience joy and elation.", "23. I don't find it easy to make decisions.", "24. I don't have a particular sense of meaning and purpose in my life.", 
 "25. I feel I have a great deal of energy.", "26. I usually have a good influence on events.", "27. I don't have fun with other people.", 
 "28. I don't feel particularly healthy.", "29. I don't have particularly happy memories of the past."]

 //calculate total. Add up all the numbers in the array and divide by 28. Round to 1 decimal place
  const calculateTotal = async () => {
    let n = 0; 
    let unansweredQ = false
    console.log(current); 
    for (let i = 0; i<current.length; i++) {
        n+= current[i];
        if (current[i] === 0) {
          unansweredQ = true
        }
    }

    if (unansweredQ) {
      return
    }

    const calc = n/29;
    const fixedCalc = calc.toFixed(1); 
    setTotal(fixedCalc); 
    calculateResult(fixedCalc);

    let testRes = {}
    testRes["user"] = userId
    testRes["testId"] = "happiness"
    testRes["score"] = fixedCalc

    submitData(testRes)
    console.log(testRes)
  }

  //updates the array by updating the element at given index
  const handleAgree = (index, num) => {
      current[index.index] = num;

  }

  const calculateResult = (num) => {
      // eslint-disable-next-line
      if (num == 6.0) {
        setResult("This means that you are extremely happy.");
      }
      else if (num > 5) {
        setResult("This means that you are very happy.");
      }
      else if (num > 4) {
        setResult("This means that you are rather happy; pretty happy.");
      }
      // eslint-disable-next-line
      else if (num == 4.0) {
        setResult("This means that you are somewhat happy or moderately happy. This is what the average person scores.");
      }
      else if (num > 3) {
        setResult("This means that you are not particularly happy or unhappy.");
      }
      else if (num > 2) {
        setResult("This means that you are somewhat unhappy.");
      }
      else {
        setResult("This means that you are not happy.");
      }
  }

  // call API to submit the form data 
  const submitData = (testData) => {
  const csrfToken = getCookie("csrftoken")
  console.log(JSON.stringify(testData))
  axios.post(
      `${baseUrlAWS}/create-progress-test-score-entry/`,
      testData,
      {
          headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
          },
      })
      .then(response => {
          console.log(response)
      })
      .catch(error => {
          if (error.response && error.response.status === 500) {
              return null
          } else {
              console.error('Error fetching form data:', error)
              throw error
          }
      })

      setSubmitted(true)
  }

   //I used BootStrap CSS so the classNames won't work unless it is installed and imported in index.js 

  return (
    <div className="App" style={{marginTop: "65px"}}>
        <div className="container-fluid m-4">
          <div className="row">
            <div className="col-5">
              <h5><strong>Questions</strong></h5>
            </div>
            <div className="col smallfont d-flex justify-content-center">
              <p>Strongly Disagree</p>
            </div>
            <div className="col smallfont d-flex justify-content-center">
              <p>Moderately Disagree</p>
            </div>
            <div className="col smallfont d-flex justify-content-center">
              <p>Slightly Disagree</p>
            </div>
            <div className="col smallfont d-flex justify-content-center">
              <p>Slightly Agree</p>
            </div>
            <div className="col smallfont d-flex justify-content-center">
              <p>Moderately Agree</p>
            </div>
            <div className="col smallfont d-flex justify-content-center">
              <p>Strongly Agree</p>
            </div>
          </div>
        {questions.map((item, index) => {
          const score = [1,2,3,4,5,6]
          if (index === 0 || index === 4 || index === 5 || index === 9 || index === 12 || index === 13
             || index === 18 || index === 22 || index === 23 || index === 26 || index === 27 ||  index === 28) {
              score.reverse(); //taking into account the scores that need to be put into reverse. Will temporary reverse the array for given question
          }
          return <div className ="row d-flex align-items-center" key = {index}><div className="col-5 mt-2"><p className = "h6" key = {index}>{item}</p></div>
            <div className="col d-flex justify-content-center mt-1">
              <input type = "radio" name = {index} id ="stronglydisagree" onClick = {() => handleAgree({index}, score[0])}/>        
            </div>
            <div className="col d-flex justify-content-center mt-1">
              <input type = "radio" name = {index} id ="moderatelydisagree" onChange = {() => handleAgree({index}, score[1])}/>
            </div>
            <div className="col d-flex justify-content-center mt-1">
              <input type = "radio" name = {index} id ="slightlydisagree" onChange = {() => handleAgree({index}, score[2])}/>
            </div>
            <div className="col d-flex justify-content-center mt-1">
              <input type = "radio" name = {index} id ="slightlyagree" onChange = {() => handleAgree({index}, score[3])}/>
            </div>
            <div className="col d-flex justify-content-center mt-1">
              <input type = "radio" name = {index} id ="moderatelyagree" onChange = {() => handleAgree({index}, score[4])}/>
            </div>
            <div className="col d-flex justify-content-center mt-1">
              <input type = "radio" name = {index} id ="stronglyagree" onChange = {() => handleAgree({index}, score[5])}/>
            </div>
            </div>
        })}
        <div className="row mt-4">
          <div className="col d-flex flex-column align-items-center text-center ">
            {(!submittedToday && !submitted) && (
              <button className="btn btn-primary" onClick = {() => calculateTotal()}>Calculate Score</button>
            )}
            {(submittedToday || submitted) && (
              <button className="btn btn-primary">Already Submitted Today</button>
            )}
            <div>{total ? <h3>Your score: {total}</h3> : <div></div>}</div>
            <div>{result? <h3>{result}</h3>: <div></div>}</div>
            </div>
        </div>
        </div>
    </div>
  );
}

export default HappinessTest;