import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import './CalculatorResponse.css';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';
import axios from 'axios';
import { getCookie } from '../utils/cookie';
import arrow from '../components/images/middle-arrow.jpg';
import LoadingIcon from '../components/LoadingIcon';


function CalculatorResponse({ score, estiScore }) {
  // const [score, setScore] = useState(null);
  const { user, dispatch } = useAuthContext();
  let navigate = useNavigate();
  const handleClick = () => {
    navigate("/subscribe");
  };

  return (
    <div className="response" >
      <div className="introduction">
        <div className="subtitle">Longevity</div>
        <div className="title">Life Span Estimate</div>
        <div className="text">
          We estimate your life span and how much you can
        </div>
        <div className="text">
          increase based on <span>scientific research</span> and{" "}
          <span>AI</span>
        </div>
      </div>



      <div className='result'>
        <div style={{ width: '33.33%' }}>
          <div>Current Estimate</div>
          {!score ? (
            <div className="spinner-container">
              <div
                className="spinner"
                style={{ width: "4rem", height: "4rem", marginTop: "5px" }}
              ></div>
              <p>Loading data, please wait...</p>
            </div>
          ) : (

            <div
              className="number"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span>{parseFloat(Math.min(score, 94)).toFixed(1)}</span>
              <span style={{ fontSize: "0.5em", marginLeft: "5px" }}>
                years
              </span>

            </div>
          )}
        </div>
        <div className="middle">
          <img
            src={arrow}
            alt="arrow"
            style={{ width: "60px", height: "55px" }}
          />
        </div>

        <div style={{ width: '33.33%' }}>
          <div>We can help you increase it to</div>
          {!estiScore ? (
            <div className="spinner-container">
              <div
                className="spinner"
                style={{ width: "4rem", height: "4rem", marginTop: "5px" }}
              ></div>
              <p>Loading data, please wait...</p>
            </div>
          ) : (

            <div className='number' style={{ display: 'flex', alignItems: 'center' }}>
              <span>{parseFloat(Math.min(estiScore, 94)).toFixed(1)}</span>
              <span style={{ fontSize: '0.5em', marginLeft: '5px' }}>years</span>
            </div>
          )}
        </div>

        
      </div>
      
      {!user.isPremium &&(
              <div className='end' onClick={handleClick}>
              Subscribe for a personalized program to get you to your desired health
              goals
            </div>
      )}

    </div>
  );
}

export default CalculatorResponse;
