import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthContext } from "../hooks/useAuthContext" 

function ProtectedRoute({ children, requirePremium, page}) {

    const { user } = useAuthContext();
    if (!user){
      return <Navigate to="/signin" />;
    }
    if (requirePremium && !user.isPremium){
      return <Navigate to="/subscribe" />;
    }
  return (
    children
  )
}

export default ProtectedRoute