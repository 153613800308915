const fiberLabel = "Fiber";
const proteinLabel = "Protein";
const magnesiumLabel = "Magnesium";
const manganeseLabel = "Manganese";
const phosphorusLabel = "Phosphorus";
const potassiumLabel = "Potassium";
const vitaminALabel = "Vitamin A";
const vitaminCLabel = "Vitamin C";
const vitaminDLabel = "Vitamin D";
const vitaminKLabel = "Vitamin K";
const biotinLabel = "Biotin";
const chlorideLabel = "Chloride";
const chromiumLabel = "Chromium";
const copperLabel = "Copper";
const folateFolicAcidLabel = "Folate/Folic Acid";
const molybdenumLabel = "Molybdenum";
const niacinLabel = "Niacin (B3)";
const pantothenicAcidLabel = "Pantothenic Acid";
const riboflavinLabel = "Riboflavin (B2)";
const seleniumLabel = "Selenium";
const thiamineLabel = "Thiamine (B1)";
const cholineLabel = "Choline";
const vitaminB6Label = "Vitamin B6";
const vitaminB12Label = "Vitamin B12";
const vitaminELabel = "Vitamin E";
const zincLabel = "Zinc";
const iodineLabel = "Iodine";
const ironLabel = "Iron";
const nickelLabel = "Nickel";
const fishOilOmega3Label = "Fish oil/Omega 3";
const melatoninLabel = "Melatonin";
const turmericLabel = "Turmeric";
const garlicLabel = "Garlic";
const matchaGreenTeaLabel = "Matcha/Green Tea";
const plantSterolsLabel = "Plant Sterols";
const nmnLabel = "NMN";
const nrLabel = "NR";
const calciumAKGLabel = "Calcium AKG/AKG";
const alphaLipoicAcidLabel = "Alpha Lipoic Acid";
const quercetinLabel = "Quercetin";
const pqqLabel = "PQQ";
const hyaluronicAcidLabel = "Hyaluronic Acid";
const chlorellaLabel = "Chlorella";
const spirulinaLabel = "Spirulina";
const reveratrolLabel = "Resveratrol";
const coQ10Label = "CoQ10";
const acetylLCarnitineLabel = "Acetyl-L-carnitine";
const oliveOilLabel = "Olive Oil";
const luteinLabel = "Lutein";
const milkThistleLabel = "Milk Thistle";
const spermidineLabel = "Spermidine";
const creatineLabel = "Creatine";
const trimethylGlycineLabel = "Trimethyl Glycine";
const calciumLabel = "Calcium";
const supplementsLabels = "Do you use any of the following supplements?";

export {
    fiberLabel, proteinLabel, magnesiumLabel, manganeseLabel, phosphorusLabel, potassiumLabel, vitaminALabel, vitaminCLabel, vitaminDLabel, vitaminKLabel,
    biotinLabel, chlorideLabel, chromiumLabel, copperLabel, folateFolicAcidLabel, molybdenumLabel, niacinLabel, pantothenicAcidLabel, riboflavinLabel, seleniumLabel,
    thiamineLabel, cholineLabel, vitaminB6Label, vitaminB12Label, vitaminELabel, zincLabel, iodineLabel, ironLabel, nickelLabel, fishOilOmega3Label,
    melatoninLabel, turmericLabel, garlicLabel, matchaGreenTeaLabel, plantSterolsLabel, nmnLabel, nrLabel, calciumAKGLabel, alphaLipoicAcidLabel, quercetinLabel,
    pqqLabel, hyaluronicAcidLabel, chlorellaLabel, spirulinaLabel, reveratrolLabel, coQ10Label, acetylLCarnitineLabel, oliveOilLabel, luteinLabel, milkThistleLabel,
    spermidineLabel, creatineLabel, trimethylGlycineLabel, calciumLabel, supplementsLabels
};
