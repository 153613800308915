import React, { useState, useMemo, useEffect } from "react";
import { Line } from "react-chartjs-2";
import axios from "axios";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css'; 

function Testchart() {
  const baseUrlAWS = process.env.REACT_APP_baseUrl;
  const baseUrl = `${baseUrlAWS}api/myprogress`;

  const [progressData, setProgressData] = useState([]);
  
  const userId = useMemo(() => {
    let user = localStorage.getItem("mysUser");
    user = user
      ? JSON.parse(user)
      : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
    return user.userId;
  }, []);

  const fetchData = async (value) => {
    axios
      .get(`${baseUrl}/read-all-progress-by-user/${userId}/${value}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setProgressData(response.data.all_progress_responses);
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          return null;
        } else {
          console.error("Error fetching data: ", error);
          throw error;
        }
      });
  };

  useEffect(() => {
    fetchData("all");
  }, []);

  const options = (chartName) => ({
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `${chartName.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} Progress`,
        font: {
          family: "Montserrat",
          size: 14,
        },
      },
    },
  });

  const getChartData = (chartName) => {
    switch (chartName) {
      case "weight":
        return progressData.map((data) => data.weight);
      case "walking_speed":
        return progressData.map((data) => data.walkspeed); 
      case "grip_strength":
        return progressData.map((data) => data.grip); 
      case "blood_pressure_systolic":
        return progressData.map((data) => data.systolic); 
      case "blood_pressure_diastolic":
        return progressData.map((data) => data.diastolic); 
      case "cholesterol_total":
        return progressData.map((data) => data.chototal); 
      case "cholesterol_ldl":
        return progressData.map((data) => data.choldl); 
      case "cholesterol_hdl":
        return progressData.map((data) => data.chohdl); 
        return [];
    }
  };

  const createChartData = (chartName) => ({
    labels: progressData.map((data) => data.submission_date.substring(0, 10)),
    datasets: [
      {
        label: chartName.charAt(0).toUpperCase() + chartName.slice(1),
        data: getChartData(chartName),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  });

  return (
    <div>
      <Splide options={{ perPage: 1, arrows: true, pagination: true, drag: 'free' }}>
        <SplideSlide>
          <Line options={options("weight")} data={createChartData("weight")} />
        </SplideSlide>
        <SplideSlide>
          <Line options={options("walking_speed")} data={createChartData("walking_speed")} />
        </SplideSlide>
        <SplideSlide>
          <Line options={options("grip_strength")} data={createChartData("grip_strength")} />
        </SplideSlide>
        <SplideSlide>
          <Line options={options("blood_pressure_systolic")} data={createChartData("blood_pressure_systolic")} />
        </SplideSlide>
        <SplideSlide>
          <Line options={options("blood_pressure_diastolic")} data={createChartData("blood_pressure_diastolic")} />
        </SplideSlide>
        <SplideSlide>
          <Line options={options("cholesterol_total")} data={createChartData("cholesterol_total")} />
        </SplideSlide>
        <SplideSlide>
          <Line options={options("cholesterol_ldl")} data={createChartData("cholesterol_ldl")} />
        </SplideSlide>
        <SplideSlide>
          <Line options={options("cholesterol_hdl")} data={createChartData("cholesterol_hdl")} />
        </SplideSlide>
      </Splide>
    </div>
  );
}

export default Testchart;
