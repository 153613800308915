const sleepOptions = [
    "Not during the past month",
    "Less than once a week",
    "Once or twice a week",
    "Three or more times a week"
];

const sleepQualityOptions = [
    "Very Good",
    "Fairly Good",
    "Fairly Bad",
    "Very Bad"
];

const frequencyOptions = [
    "Not during the past month",
    "Less than once a week",
    "Once or twice a week",
    "Three or more times a week"
];

const enthusiasmLevelOption = [
    "No problem at all",
    "Only a very slight problem",
    "Somewhat of a problem",
    "A very big problem"
];

export { sleepOptions, sleepQualityOptions, frequencyOptions, enthusiasmLevelOption };
