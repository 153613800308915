import * as React from "react";
import axios from "axios";

const MemberTable = ({ 
  orgData, orgtype, members, fetchOrganizationData, 
  setShowPopup, setPopupContent, setPopupConfirmFunction
}) => {
  const userid = JSON.parse(localStorage.getItem("mysUser")).userId;

  const baseURL = process.env.REACT_APP_baseUrl;
  const removeMemberURL = "api/organizations/removemember";
  const transferManagerURL = "api/organizations/transfermanager";

  const handleRemoveMember = (email) => {
    axios
      .post(baseURL + removeMemberURL, {
        user_id: userid,
        email: email,
        orgtype: orgtype,
      })
      .then((_) => {
        fetchOrganizationData();
      })
      .catch((error) => {
        console.error("Error removing member: ", error);
      });
  };

  const handleTransferManager = (member) => {
    setShowPopup(true);
    setPopupContent(`Are you sure you want to transfer the owner role to ${member.firstname} ${member.lastname}?`);
    setPopupConfirmFunction(() => () => {
      axios
        .post(baseURL + transferManagerURL, {
          user_id: userid,
          email: member.email,
          orgtype: orgtype,
        })
        .then(() => {
          console.log("Owner transferred to: ", member.email);
          fetchOrganizationData();
        })
        .catch((error) => {
          console.log("Error transferring owner: ", error);
        });
    })
  };

  return (
    <div className="org-item">
      <h4>Active Members: </h4>
      <table className="member-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {members.map((member) => (
            <tr key={member.email}>
              <td>
                {member.firstname} {member.lastname}
              </td>
              <td>
                {member.email === orgData.content.manager_email
                  ? `$${orgData.content.base_cost}`
                  : `$${orgData.content.addl_member_cost}`}
              </td>
              <td>
                {member.email !== orgData.content.manager_email ? (
                  orgData.is_manager ? (
                    <div>
                      <button
                        className="remove-button"
                        onClick={() => handleRemoveMember(member.email)}
                      >
                        Remove
                      </button>
                      <button
                        className="transfer-button"
                        onClick={() => handleTransferManager(member)}
                      >
                        Transfer Ownership
                      </button>
                    </div>
                  ) : null
                ) : (
                  "Owner"
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MemberTable;
