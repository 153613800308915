import axios from 'axios';
import { getCookie } from "../../../utils/cookie";
const baseUrl = process.env.REACT_APP_baseUrl;
//const baseUrl = process.env.REACT_APP_localBaseUrlAWS;

const baseUrlAWS = `${baseUrl}api/lifestyle`
const baseUrlUser = `${baseUrl}api/users`

// const baseUrlUser = `${baseUrlAWS}api/users`


// call API to submit the form data to initial plan
export const submitData = (formData) => {
  const csrfToken = getCookie("csrftoken"); 
  console.log(formData);
  return axios.post(
      `${baseUrlAWS}/submit-initial-plan/`,
      formData,
      {
          headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
          },
      })
      .then(response => {
        console.log("response");
        console.log(response);
      })
      .catch(error => {
          if (error.response && error.response.status === 500) {
            return null;
          } else {
            console.error('Error submitting form data:', error);
            throw error;
          }
      });
};

// call API to save the form data
export const saveData = (formData) => {
  const csrfToken = getCookie("csrftoken"); 
  console.log(formData);
  return axios.post(
      `${baseUrlAWS}/update-lifestyle_data/`,
      formData,
      {
          headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
          },
      })
      .then(response => {
        console.log("response");
        console.log(response);
      })
      .catch(error => {
          if (error.response && error.response.status === 500) {
            return null;
          } else {
            console.error('Error saving form data:', error);
            throw error;
          }
      });
};

// call API to retrieve the form data
export const fetchData = (userId) => {
  return axios.get(
      `${baseUrlAWS}/read-one-lifestyle-response/${userId}`,
      {
          headers: {
          "Content-Type": "application/json",
          },
      })
      .then(response => response)
      .catch(error => {
          if (error.response && error.response.status === 500) {
            return null;
          } else {
            console.error('Error fetching form data:', error);
            throw error;
          }
      });
};

// call API to submit the form data and get the score
export const fetchProbData = async (dataToSend) => {
  const csrfToken = getCookie("csrftoken"); // Get the CSRF token from cookies
  return axios.post(
    `${baseUrlAWS}/create-object-get-score-update/`,
    dataToSend,
    {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
    }
  )
  .then(response => response.data)
  .catch(error => {
    console.error('Error submitting form data:', error);
    throw error;
  });
};

export const fetchUserData = (userId) => {
  return axios.get(
      `${baseUrlUser}/get-user-data/${userId}`,
      // {
      //     headers: {
      //         "Content-Type": "application/json",
      //     },
      // })
      )
      .then(response => response.data)
      .catch(error => {
          if (error.response && error.response.status === 500) {
              return null
          } else {
              console.error('Error fetching form data:', error)
              throw error
          }
      })

}