// this file contains all options for all intakefrom parameters

//=============================== About Me ===============================
const genderOptions = ['Male', 'Female'];
const raceOptions = ["White", "Black", "Hispanic", "Asian", "Other Race - including multi-racial"];
const educationOptions = ["Less than 9th grade", "9th grade - 11th grade", "High school graduate/GED or equivalent", "Some college or AA degree", "Undergraduate degree or higher", "I decline to answer", "I don't know"];
const incomeOptions = ["$0 to $4,999", "$5,000 to $9,999", "$10,000 to $14,999", "$15,000 to $19,999", "$20,000 to $24,999", "$25,000 to $34,999", "$35,000 to $44,999", "$45,000 to $54,999", "$55,000 to $64,999", "$65,000 to $74,999", "$75,000 to $99,999", "$100,000 and Over", "I decline to answer", "I don't know"];
const workingOptions = ["Working", "Retired", "Not working"];
const maritalOptions = ["Married", "Widowed", "Divorced", "Separated", "Never married", "Living with partner", "I decline to answer", "I don't know"];
const cigarettesOptions = ["Never used", "Former user", "Current user", "I decline to answer", "I don't know"];

export { genderOptions, raceOptions, educationOptions, incomeOptions, workingOptions, maritalOptions, cigarettesOptions };



