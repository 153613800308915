import React from 'react'

export default function DateInput({label, options, disabled, instruction, name, onChange, value, error, max}) {
    const errorMessage = 'Error: Please fill out this field.';
    if(!options){
        options = [];
    }
    const handleChange = (event) => {
        onChange(name, event.target.value);
    };

  return (
    // <div class="form-group row mb-1">
        <div class="col">
        <div class="d-flex justify-content-center">
            <label>{label}</label>
            </div>
            <div class="input-group">
            <input type="date" class="form-control" placeholder={label}  disabled={disabled} onChange={handleChange} value={value} 
            max={max}  // Use max to restrict date selection
            />
        </div>
        {error && <p style={{color: 'red', fontSize: '16px', marginBottom: '0', marginLeft: '5px'}}>{errorMessage}</p>}
        {instruction &&
            <div class="d-flex justify-content-center">
                <small class="text-muted">{instruction}</small>
            </div>
        }
        </div>
    // </div>
  )
}
