const stretchingLabel = "Stretching";
const boneStrengtheningLabel = "Bone Strengthening";
const balanceActivityLabel = "Balance Activity";
const meditationLabel = "Meditation";
const yogaLabel = "Yoga";
const activitiesSaunaBathingLabel = "Sauna Bathing";
const coldExposureLabel = "Exposure to Extreme Cold";
const lowIntensityLabel = "Low Intensity (Long distance walking, swimming, or cycling, pickleball, golf)";
const moderateIntensityLabel = "Moderate Intensity (jogging, cycling or swimming at a comfortable pace, tennis)";
const highIntensityLabel = "High Intensity (Sprints, Interval training, short distance swimming)";
const cardiovascularSaunaBathingLabel = "Sauna Bathing";
const strengthTrainingLabel = "Strength Training";

export { stretchingLabel, boneStrengtheningLabel, balanceActivityLabel, meditationLabel, yogaLabel, activitiesSaunaBathingLabel, coldExposureLabel, lowIntensityLabel, moderateIntensityLabel, highIntensityLabel, cardiovascularSaunaBathingLabel, strengthTrainingLabel };