const processedMeatName = "processedMeat";          // Corresponds to "processedMeat"
const poultryName = "poultry";                      // Corresponds to "poultry"
const redMeatName = "redMeat";                      // Corresponds to "redMeat"
const fishName = "shellFish";                       // Best match is "otherFish"
const refinedGrainName = "refinedGrain";            // Corresponds to "refinedGrain"
const wholeGrainName = "wholeGrain";                // Corresponds to "wholeGrain"
const vegetablesName = "vegetables";                // Corresponds to "vegetables"
const fruitName = "fruit";                          // Corresponds to "fruit"
const fruitAndVegetableName = 'fruit_and_vegetables'; // Corresponds to "fruit_and_vegetable"
const legumesName = "legumes";                      // Corresponds to "legumes"
const dairyName = 'dairy';                          // Corresponds to "dairy"
const milkName = "milk";                            // Corresponds to "milk"
const cheeseName = "cheese";                        // Corresponds to "cheese"
const butterName = "butter";                        // Corresponds to "butter"
const dairyOtherName = "otherDairy";                // Corresponds to "otherDairy"
const sugarySodaName = "soda_sugar";                // Corresponds to "soda_sugar"
const sugaryCakesName = "cakes_sugar";              // Corresponds to "cakes_sugar"
const sugaryCookiesName = "cookies_sugar";          // Corresponds to "cookies_sugar"
const sugaryCandyName = "candy_sugar";              // Corresponds to "candy_sugar"
const sugaryIceCreamName = "ice_cream_sugar";       // Corresponds to "ice_cream_sugar"
const sugaryAddedSugarName = "added_sugar";         // Corresponds to "added_sugar"
const sugaryOtherName = "other_sugar";              // Corresponds to "other_sugar"
const artificiallySodaName = "soda_sweetener";      // Corresponds to "soda_sweetener"
const artificiallyCakesName = "cakes_sweetener";    // Corresponds to "cakes_sweetener"
const artificiallyCookiesName = "cookies_sweetener";// Corresponds to "cookies_sweetener"
const artificiallyCandyName = "candy_sweetener";    // Corresponds to "candy_sweetener"
const artificiallyIceCreamName = "ice_cream_sweetener"; // Corresponds to "ice_cream_sweetener"
const artificiallyAddedSugarName = "added_sweetener";   // Corresponds to "added_sweetener"
const artificiallyOtherName = "other_sweetener";    // Corresponds to "other_sweetener"
const waterIntakeName = "water";                    // Corresponds to "water"
const saturatedFatIntakeName = "fat_saturated";     // Corresponds to "fat_saturated"
const transFatIntakeName = "fat_trans";             // Corresponds to "fat_trans"
const intermittentFastingName = "fastingEatingSchedule"; // Corresponds to "fastingEatingSchedule"
const calorieRestrictionName = "calorieRestriction";// Corresponds to "calorieRestriction"
const refinedSugarName = 'refinedSugar';
const artificialSugarName = 'artificialSugar';

export {
    processedMeatName, poultryName, redMeatName, fishName, refinedGrainName, wholeGrainName,
    vegetablesName, fruitName, fruitAndVegetableName, legumesName, dairyName, milkName, cheeseName, butterName, dairyOtherName,
    sugarySodaName, sugaryCakesName, sugaryCookiesName, sugaryCandyName, sugaryIceCreamName,
    sugaryAddedSugarName, sugaryOtherName, artificiallySodaName, artificiallyCakesName,
    artificiallyCookiesName, artificiallyCandyName, artificiallyIceCreamName,
    artificiallyAddedSugarName, artificiallyOtherName, waterIntakeName, saturatedFatIntakeName,
    transFatIntakeName, intermittentFastingName, calorieRestrictionName, refinedSugarName,
    artificialSugarName
};
