const upsetCountName = "upsetUnexpectedly";                 // Corresponds to "upsetUnexpectedly"
const controlCountName = "unableControlThings";             // Corresponds to "unableControlThings"
const stressCountName = "nervousStressed";                  // Corresponds to "nervousStressed"
const confidenceCountName = "abilityHandlePersonalProblems";// Corresponds to "abilityHandlePersonalProblems"
const wayCountName = "thingsGoingYourWay";                  // Corresponds to "thingsGoingYourWay"
const copeCountName = "couldNotCopeAllThings";              // Corresponds to "couldNotCopeAllThings"
const irritationsCountName = "controlIrritations";          // Corresponds to "controlIrritations"
const topCountName = "onTopOfThings";                       // Corresponds to "onTopOfThings"
const angerCountName = "angeredThingsOutsideOfControl";     // Corresponds to "angeredThingsOutsideOfControl"
const difficultiesCountName = "couldNotOvercomeDifficulties";// Corresponds to "couldNotOvercomeDifficulties"

const stressScore = "stress_level_score";

export { 
    upsetCountName, controlCountName, stressCountName, confidenceCountName, 
    wayCountName, copeCountName, irritationsCountName, topCountName, 
    angerCountName, difficultiesCountName,
    
    stressScore
};
