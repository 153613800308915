import React from 'react'
import Menu from '../../../components/questionnaireElement/Menu'
import InputSingle from '../../../components/questionnaireElement/InputSingle'
import InputMulti from '../../../components/questionnaireElement/InputMulti'
import DateInput from '../../../components/questionnaireElement/DateInput'
import { useEffect, useState } from 'react'
import { genderOptions, raceOptions, educationOptions, incomeOptions, workingOptions, maritalOptions, cigarettesOptions } from './OptionsTable/AboutMeOptions'
import { genderLabel, dateOfBirthLabel, raceLabel, weightLabel, heightLabel, educationLabel, incomeLabel, workingStatusLabel, maritalStatusLabel, cigarettesUseLabel, formerCigarettesYearsLabel, currentCigarettesYearsLabel, currentCigarettesPerDayLabel, alcoholDrinksPerWeekLabel } from './Lables/AboutMeLabels';
import { DATE_OF_BIRTH, RACE, GENDER, WEIGHT, HEIGHT, EDUCATION, INCOME, WORKING_STATUS, MARITAL_STATUS, CIGARETTES_USE, FORMER_CIGARETTES_YEARS, CURRENT_CIGARETTES_YEARS, CURRENT_CIGARETTES_PER_DAY, ALCOHOL_DRINKS_PER_WEEK } from './NameTable/AboutMeName'
const formDataKey = 'IntakeForm'; 
export default function AboutMe({heightUnit, weightUnit, onChange, cigaretteStatus, setHasChanges, dataState, setDataState, errors, maxDate}) {
  const [formData, setformData] = useState({});
  const handleInputChange = (name, value) => {
    // Notify the parent that the form has changed
    setHasChanges(true);
    
    let newValue = {...dataState, [name]:['dob', 'toBedTime', 'getUpTime'].includes(name) ?
      value : parseFloat(value)}
    setDataState(newValue)
  };
  // console.log(dataState)
  return (
          <form>
            <div class='inlineContainer'>
              <small class="form-text">About Me</small>
            </div>
            <InputMulti inputs={[
              {type: 'DateInput', label: dateOfBirthLabel, name: DATE_OF_BIRTH, max: maxDate },
              {type: 'Menu', label: raceLabel, options: raceOptions, name: RACE},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors}
            />
            <InputMulti inputs={[
              {type: 'Menu', label: genderLabel, options: genderOptions, name: GENDER},
              {type: 'InputSingle', label: weightLabel, options: [weightUnit], name: WEIGHT},
              {type: 'InputSingle', label: heightLabel, options: [heightUnit], name: HEIGHT},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors}
            />
            <Menu label={educationLabel} options={educationOptions} name={EDUCATION} onChange={handleInputChange} value={dataState[EDUCATION]} error={errors[EDUCATION]}/>
            <InputMulti inputs={[
              {type: 'Menu', label: incomeLabel, options: incomeOptions, name: INCOME},
              {type: 'Menu', label: workingStatusLabel, options: workingOptions, name: WORKING_STATUS},
            ]}
            onChange={handleInputChange} formData={formData} value={dataState} error={errors}
            />
            <Menu label={maritalStatusLabel} options={maritalOptions} name={MARITAL_STATUS} onChange={handleInputChange} value={dataState[MARITAL_STATUS]} error={errors[MARITAL_STATUS]}/>
            <Menu label={cigarettesUseLabel} options={cigarettesOptions} name={CIGARETTES_USE} onChange={handleInputChange} value={dataState[CIGARETTES_USE]} error={errors[CIGARETTES_USE]}/>
            {dataState[CIGARETTES_USE] === 1 && (
                <InputSingle label={formerCigarettesYearsLabel} name={FORMER_CIGARETTES_YEARS} onChange={handleInputChange} value={dataState[FORMER_CIGARETTES_YEARS]} error={errors[FORMER_CIGARETTES_YEARS]}/>
            )}
            {dataState[CIGARETTES_USE] === 2 && (
                <InputMulti inputs={[
                {type: 'InputSingle', label: currentCigarettesYearsLabel, name: CURRENT_CIGARETTES_YEARS},
                {type: 'InputSingle', label: currentCigarettesPerDayLabel, name: CURRENT_CIGARETTES_PER_DAY},
                ]}
                onChange={handleInputChange} formData={formData} value={dataState} error={errors}
                />
            )}
            <InputSingle label={alcoholDrinksPerWeekLabel} name={ALCOHOL_DRINKS_PER_WEEK} onChange={handleInputChange} value={dataState[ALCOHOL_DRINKS_PER_WEEK]} error={errors[ALCOHOL_DRINKS_PER_WEEK]}/>
          </form>
  )
}
