import React from 'react';
import './LoadingIcon.css'; 

const Loading = () => (
  <div className='loading-overlay'>
    <div className='spinner-container'>
      <div className='spinner'></div>
      <p className='spinner-text'>Loading data, please wait...</p>
    </div>
  </div>
  
);

export default Loading;
