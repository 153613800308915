import React from 'react'

export default function Menu({label, options, name, onChange, value, error}) {
  const errorMessage = 'Error: Please select from the drop-down menu.'
  const handleChange = (event) => {
    onChange(name, event.target.value);
  };
  return (
    <div class="col">
        <div class="d-flex justify-content-center">
        <label>{label}</label>
        </div>
        <select class="form-control" onChange={handleChange} value={value}>
        <option value="" id='0'></option>
            {options.map((option, index) => (
                <option value={index} id={index + 1}>{option}</option>
            ))}
        </select>
        {error && <p style={{color: 'red', fontSize: '16px', marginBottom: '0', marginLeft: '5px'}}>{errorMessage}</p>}
        {/* {!value && <p>Error: please select from the drop down menu.</p>} */}
    </div>
  )
}
