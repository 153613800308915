import React, { useState } from 'react'

export default function InputCheckBox({ label, options, name, onChange, value }) {
    const handleChange = (event) => {
        const optionName = event.target.name;
        const isChecked = event.target.checked;
        
        const updatedValues = {
            [optionName]: isChecked
        }

        onChange(optionName, updatedValues[optionName]);
    };

    return (
        <div className="col">
            <div className="d-flex justify-content-center">
                <label>{label}</label>
            </div>
            <div className="form-control">
                {options.map((option, index) => (
                    <div key={index}>
                        <input
                            type="checkbox"
                            name={name[index]}
                            checked={value[name[index]]}
                            onChange={handleChange}
                        />
                        <label>{option}</label>
                    </div>
                ))}
            </div>
        </div>
    );
}
