import React, { useState, useMemo, useEffect } from 'react';
import axios from 'axios'
import {getCookie} from "../../../utils/cookie"
import { fetchScores } from "../action";

const MemoryTestForm = () => {

  const [submittedToday, setSubmittedToday] = useState(true)

  useEffect(() => {
    const fetchTestScores = async (userId) => {
        try {
            const data = await fetchScores(userId)

            if (data.filter(data => data.testId === 'selfreaction').length > 0) {
                const testDate = new Date(data.filter(data => data.testId === 'selfreaction')[0].submission_date.substring(0,10).split('-')).toUTCString().substring(0,16)
                const currDate = new Date().toUTCString().substring(0,16)

                if (testDate === currDate) {
                  setSubmittedToday(true)
                } else {
                  setSubmittedToday(false)
                }
            } else {
              setSubmittedToday(false)
            }

        } catch (error) {
            console.error('Error fetching test scores', error)
        }
    }
    fetchTestScores(userId)
  }, [])

  const [responses, setResponses] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [score, setScore] = useState(null);
  const baseUrl = process.env.REACT_APP_baseUrl;
  const baseUrlAWS = `${baseUrl}api/myprogress`

  const userId = useMemo(() => {
    let user = localStorage.getItem("mysUser");
      user = user
        ? JSON.parse(user)
        : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
    return user.userId;
  })

  const handleResponseChange = (index, value) => {
    const newResponses = [...responses];
    newResponses[index] = value;
    setResponses(newResponses);
  };

  const calculateScore = () => {
    let score = 0;
    for (let i = 0; i < responses.length; i++) {
      if (responses[i] === 'sometimes') {
        score += 1;
      } else if (responses[i] === 'often') {
        score += 2;
      } else if (responses[i] === 'always') {
        score += 3;
      }
    }

    if (responses.length < 10) {
      return -1
    } else {
      return score
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    const score = calculateScore();

    if (score === -1) {
      return
    }

    setScore(score);
    setSubmitted(true);

    let testRes = {}
    testRes["user"] = userId
    testRes["testId"] = "selfreaction"
    testRes["score"] = score

    submitData(testRes)
    console.log(testRes)

  }

  const submitData = (testData) => {
    const csrfToken = getCookie("csrftoken")
    console.log(JSON.stringify(testData))
    axios.post(
        `${baseUrlAWS}/create-progress-test-score-entry/`,
        testData,
        {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken,
            },
        })
        .then(response => {
            console.log(response)
        })
        .catch(error => {
            if (error.response && error.response.status === 500) {
                return null
            } else {
                console.error('Error fetching form data:', error)
                throw error
            }
        })
    }

  const Question = ({ index, question }) => (
    <div style={{ border: '1px solid black', margin: '10px 0', padding: '10px' }}>
      <p>{index + 1}. {question}</p>
      {['never', 'sometimes', 'often', 'always'].map(response => (
        <div key={`${index}-${response}`} style={{ margin: '5px 0' }}>
          <label>
            <input
              type="radio"
              name={`response${index}`}
              value={response}
              style={{ marginRight: '10px', transform: 'scale(1.5)' }}
              checked={responses[index] === response}
              onChange={() => handleResponseChange(index, response)}
            />
            {response.charAt(0).toUpperCase() + response.slice(1)}
          </label>
        </div>
      ))}
    </div>
  );  

  if (submitted) {
    let message;
    if (score >= 20) message = '';
    else if (score >= 10) message = '';
    else message = '';
    return (
      <div style={{ textAlign: 'center', padding: '80px' }}>
        <h1>Your final score is: {score}/30</h1>
        <p>{message}</p>
      </div>
    );
  }

  return (
    <div>
      <h1 style={{ marginTop: '5rem', textAlign: 'center' }}>Memory Self-Test</h1>
      <form>
        <p style={{ textAlign: 'center' }}><em>Directions:</em> Read the items below and click on the answer choice button that best describes how true this statement is for you.</p>

        <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
          <p style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'left' }}>Questions</p>

          {[
            "I can easily remember what has been said or done in the past 24 hours.",
            "I can easily remember information for a short period of time (e.g., a phone number).",
            "I can easily hold information in my head while working with it (perform mental arithmetic, take notes while listening in class).",
            "I can easily recall information that I learn.",
            "When I read, I remember the important facts.",
            "I am easily able to recall events, things I have done or places I have been, in detail.",
            "I can easily recognize someone’s face that I have seen before.",
            "It is easy for me to remember movies I have seen.",
            "I use specific memory strategies, such as saying things over and over or making mental pictures.",
            "It is easy for me to remember where I put things."
          ].map((question, index) => <Question index={index} question={question} key={index} />)}
        </div>
        
        {!submittedToday && (
          <button
            style={{
              width: '100%',
              padding: '10px',
              marginTop: '20px',
              fontSize: '20px',
              backgroundColor: '#4CAF50',
              border: 'none',
              color: 'white',
              cursor: 'pointer'
            }}
            onClick={submitForm}
          >
            Submit
          </button>
        )}

        {submittedToday && (
          <button
            style={{
              width: '100%',
              padding: '10px',
              marginTop: '20px',
              fontSize: '20px',
              backgroundColor: 'red',
              border: 'none',
              color: 'white',
              cursor: 'pointer'
            }}
          >
            Already Submitted Today
          </button>
        )}
        
      </form>
    </div>
  );
};

export default MemoryTestForm;

