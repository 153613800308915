const stretchingName = "stretching";                  // Corresponds to "stretching"
const boneStrengtheningName = "boneStrengthen";       // No direct match, closest might be "boneStrengthen"
const balanceActivityName = "balanceActivity";        // No direct match, closest might be "balanceActivity"
const meditationName = "meditation";                  // No direct match in the provided JSON
const yogaName = "yoga";                              // Corresponds to "yoga"
const activitiesSaunaBathingName = "saunaBathing";    // Best guess is "saunaBathing" if you want to map to sauna related activity
const coldExposureName = "exposureToCold";            // Corresponds to "exposureToCold"
const lowIntensityName = "lowIntensity";              // Corresponds to "lowIntensity"
const moderateIntensityName = "moderateIntensity";    // Corresponds to "moderateIntensity"
const highIntensityName = "highIntensity";            // Corresponds to "highIntensity"
const cardiovascularSaunaBathingName = "saunaDuration"; // Same as "activitiesSaunaBathing" if we're using that for sauna
const strengthTrainingName = "muscleStrength";        // Closest match is "muscleStrength", but this might refer specifically to strength-related measures rather than activities

export { 
    stretchingName, boneStrengtheningName, balanceActivityName, meditationName, yogaName, activitiesSaunaBathingName, coldExposureName, 
    lowIntensityName, moderateIntensityName, highIntensityName, cardiovascularSaunaBathingName, strengthTrainingName
 };