import React from "react";
import "./Organizations.css";

const Popup = ({ message, onCancel, onConfirm }) => {
  return (
    <div className="popup-background">
      <div className="popup">
        <p>{message}</p>
        <div className="button-container">
          <button onClick={onCancel}>Cancel</button>
          <button onClick={onConfirm}>Confirm</button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
