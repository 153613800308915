const fiberName = "dietaryFiber";            // Corresponds to "dietaryFiber"
const proteinName = "protein";               // Corresponds to "protein"
const magnesiumName = "magnesium";           // Corresponds to "magnesium"
const manganeseName = "manganese";           // Corresponds to "manganese"
const phosphorusName = "phosphorus";         // Corresponds to "phosphorus"
const potassiumName = "potassium";           // Corresponds to "potassium"
const vitaminAName = "vitaminA";             // Corresponds to "vitaminA"
const vitaminCName = "vitaminC";             // Corresponds to "vitaminC"
const vitaminDName = "vitaminD";             // Corresponds to "vitaminD"
const vitaminKName = "vitaminK";             // Corresponds to "vitaminK"
const biotinName = "biotin";                 // Corresponds to "biotin"
const chlorideName = "chloride";             // Corresponds to "chloride"
const chromiumName = "chromium";             // Corresponds to "chromium"
const copperName = "copper";                 // Corresponds to "copper"
const folateFolicAcidName = "folateOrFolicAcid"; // Corresponds to "folateOrFolicAcid"
const molybdenumName = "molybdenum";         // Corresponds to "molybdenum"
const niacinName = "niacinB3";               // Corresponds to "niacinB3"
const pantothenicAcidName = "pantothenicAcid";   // Corresponds to "pantothenicAcid"
const riboflavinName = "riboflavinB2";       // Corresponds to "riboflavinB2"
const seleniumName = "selenium";             // Corresponds to "selenium"
const thiamineName = "thiamineB1";           // Corresponds to "thiamineB1"
const cholineName = "choline";               // Corresponds to "choline"
const vitaminB6Name = "vitaminB6";           // Corresponds to "vitaminB6"
const vitaminB12Name = "vitaminB12";         // Corresponds to "vitaminB12"
const vitaminEName = "vitaminE";             // Corresponds to "vitaminE"
const zincName = "zinc";                     // Corresponds to "zinc"
const iodineName = "iodine";                 // Matches the "iodine" (Not present in JSON data)
const ironName = "iron";                     // Corresponds to "iron"
const nickelName = "nickel";                 // Matches the "nickel" (Not present in JSON data)
const fishOilOmega3Name = "fishOilOrOmega3"; // Corresponds to "fishOilOrOmega3"
const melatoninName = "melatonin";           // Corresponds to "melatonin"
const turmericName = "turmeric";             // Matches the "turmeric" (Not present in JSON data)
const garlicName = "garlic";                 // Matches the "garlic" (Not present in JSON data)
const matchaGreenTeaName = "matchaOrGreenTea"; // Corresponds to "matchaOrGreenTea"
const plantSterolsName = "plantSterols";     // Matches the "plantSterols" (Not present in JSON data)
const nmnName = "nmn";                       // Matches the "nmn" (Not present in JSON data)
const nrName = "nr";                         // Matches the "nr" (Not present in JSON data)
const calciumAKGName = "calciumAKG";         // Matches the "calciumAKG" (Not present in JSON data)
const alphaLipoicAcidName = "alphaLipoic";   // Matches the "alphaLipoic" (Not present in JSON data)
const quercetinName = "quercetin";           // Matches the "quercetin" (Not present in JSON data)
const pqqName = "pqq";                       // Matches the "pqq" (Not present in JSON data)
const hyaluronicAcidName = "hyaluronicAcid"; // Matches the "hyaluronicAcid" (Not present in JSON data)
const chlorellaName = "chlorella";           // Matches the "chlorella" (Not present in JSON data)
const spirulinaName = "spirulina";           // Matches the "spirulina" (Not present in JSON data)
const reveratrolName = "reveratrol";         // Matches the "reveratrol" (Not present in JSON data)
const coQ10Name = "coQ10";                   // Matches the "coQ10" (Not present in JSON data)
const acetylLCarnitineName = "acetyl_L_carnitine"; // Corresponds to "acetyl_L_carnitine"
const oliveOilName = "oliveOil";             // Matches the "oliveOil" (Not present in JSON data)
const luteinName = "lutein";                 // Matches the "lutein" (Not present in JSON data)
const milkThistleName = "milkThistle";       // Matches the "milkThistle" (Not present in JSON data)
const spermidineName = "spermidine";         // Matches the "spermidine" (Not present in JSON data)
const creatineName = "creatine";             // Matches the "creatine" (Not present in JSON data)
const trimethylGlycineName = "trimethylGlycine"; // Matches the "trimethylGlycine" (Not present in JSON data)
const calciumName = "calcium";               // Matches the "calcium" (Not present in JSON data)
const supplementsNames = [
    'multiVitamin',
    'probiotics',
    'collagenPeptides'
];

export {
    fiberName, proteinName, magnesiumName, manganeseName, phosphorusName, potassiumName, vitaminAName, vitaminCName, vitaminDName, vitaminKName,
    biotinName, chlorideName, chromiumName, copperName, folateFolicAcidName, molybdenumName, niacinName, pantothenicAcidName, riboflavinName, seleniumName,
    thiamineName, cholineName, vitaminB6Name, vitaminB12Name, vitaminEName, zincName, iodineName, ironName, nickelName, fishOilOmega3Name,
    melatoninName, turmericName, garlicName, matchaGreenTeaName, plantSterolsName, nmnName, nrName, calciumAKGName, alphaLipoicAcidName, quercetinName,
    pqqName, hyaluronicAcidName, chlorellaName, spirulinaName, reveratrolName, coQ10Name, acetylLCarnitineName, oliveOilName, luteinName, milkThistleName,
    spermidineName, creatineName, trimethylGlycineName, calciumName, supplementsNames
};
