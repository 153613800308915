import React from "react";
import OrganizationContent from "./OrganizationContent";

const Family = () => {
  return (
    <OrganizationContent orgtype="F" />
  );
};

export default Family;
