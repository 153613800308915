const durationOptionsMap = [
    { value: 0, label: '0' },
    { value: 0.5, label: '1' },
    { value: 1, label: '2' },
    { value: 3, label: '3' },
    { value: 5.5, label: '4' },
    { value: 7, label: '5' },
    { value: 17.5, label: '6' },
    { value: 31.5, label: '7' },
    { value: 42, label: '8' },
  ];

const newExerciseOptionsMap = [
    { value: 0, label: '0' },
    { value: 15, label: '1' },
    { value: 45, label: '2' },
    { value: 90, label: '3' },
    { value: 150, label: '4' },
    { value: 180, label: '5' },
];

const exerciseOptions = [
    { value: 0, label: "None" },
    { value: 15, label: "1-30 minutes" },
    { value: 45, label: "31-60 minutes" },
    { value: 90, label: "60-120 minutes" },
    { value: 150, label: "121-180 minutes" },
    { value: 180, label: "181+ minutes" },
  ];

const timeOptions = [
    { value: 0, label: "Never or <1 month" },
    { value: 0.5, label: "1-3/month" },
    { value: 1, label: "1/week" },
    { value: 3, label: "2-4/week" },
    { value: 5.5, label: "5-6/week" },
    { value: 7, label: "1/day" },
    { value: 17.5, label: "2-3/day" },
    { value: 31.5, label: "4-5/day" },
    { value: 42, label: "6+/day" },
  ];

const sugarTimeOptions = [
    { value: 1, label: "Day"},
    { value: 7, label: "Week"},
    { value: 30, label: "Month"}
]

const dietOptions = [
    { value: 0, label: "No" },
    { value: 1, label: "16/8" },
    { value: 2, label: "5 days/2days" },
    { value: 3, label: "1 day fast" },
    { value: 4, label: "2 day fast" },
    { value: 5, label: "3 day fast" },
    { value: 6, label: "4 day fast" },
    { value: 7, label: "5 day fast" },
    { value: 8, label: "One meal a day" },
];

const calorieOptions = [
    { value: 0, label: "No" },
    { value: 1, label: "Yes" },
];

const physicsOptions = [
    { value: 0, label: "0 Day" },
    { value: 1, label: "1 Day" },
    { value: 2, label: "2 Days" },
    { value: 3, label: "3 Days" },
    { value: 4, label: "4 Days" },
    { value: 5, label: "5 Days" },
    { value: 6, label: "6 Days" },
    { value: 7, label: "7 Days" },
    { value: 8, label: "8 Days" },
];

const sleepOptions = [
    { value: 0, label: "Not during the past month" },
    { value: 1, label: "Less than once a week" },
    { value: 2, label: "Once or twice a week" },
    { value: 3, label: "Three or more times a week" },
];

const goodOptions = [
    { value: 0, label: "Very Good" },
    { value: 1, label: "Fairly Good" },
    { value: 2, label: "Fairly Bad" },
    { value: 3, label: "Very Bad" },
];

const problemOptions = [
    { value: 0, label: "No problem at all" },
    { value: 1, label: "Only a very slight problem" },
    { value: 2, label: "Somewhat of a problem" },
    { value: 3, label: "A very big problem" },
];

export {newExerciseOptionsMap, durationOptionsMap}