// this file contains all options for all intakefrom parameters

//=============================== Family Medical History ===============================
const familyMedicalHistoryOptions = [
    "Insulin resistance (a condition that is considered a precursor to type 2 diabetes)",
    "Type 2 diabetes",
    "High blood pressure",
    "Dyslipidemia (high blood lipid levels including high cholesterol)",
    "Cardiovascular disease",
    "Stroke",
    "Arthritis",
    "Sleep apnea",
    "Gallbladder disease",
    "Hyperuricemia (excess levels of uric acid in the blood, a risk factor for gout)",
    "Osteoarthritis",
    "Certain types of cancer, such as breast cancer, colorectal cancer, and gallbladder cancer",
    "Depression",
    "Other"
];

export {familyMedicalHistoryOptions}