const multivitamin = 'multi_vitamin'
const vitaminA = 'vitamin_a'
const vitaminB6 = 'vitamin_b6'
const vitaminB12 = 'vitamin_b12'
const folicAcid = 'folic_acid'
const vitaminC = 'vitamin_c'
const vitaminD = 'vitamin_d'
const vitaminE = 'vitamin_e'
const vitaminK = 'vitamin_k'
const calcium = 'calcium'
const collagenPeptides = 'collagen_peptide'
const fiber = 'dietary_fiber'
const omega3 = 'fish_oil_omega_3'
const greenTea = 'green_tea'
const iron = 'iron'
const magnesium = 'magnesium' 
const oliveOil = 'olive_oil'
const probiotics = 'probiotics'
const protein = 'protein_supplements'
const zinc = 'zinc'
const sleep = 'sleep'


export { multivitamin, vitaminA, vitaminB6, vitaminB12, folicAcid, vitaminC, vitaminD,
    vitaminE, vitaminK, calcium, collagenPeptides, fiber, omega3, greenTea, iron,
    magnesium, oliveOil, probiotics, protein, zinc, sleep}