import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

function useRouteChangeTracker() {
  const location = useLocation();

  useEffect(() => {
    // This function sends page view events to Google Analytics
    const sendPageView = (location) => {
      // Construct the path, which is typically the pathname + search string
      const pagePath = location.pathname + location.search;

      // Use `ReactGA.send` to track the page view
      ReactGA.send({ hitType: 'pageview', page: pagePath });
    };

    // Call sendPageView whenever the location changes
    sendPageView(location);
  }, [location]);
}

export default useRouteChangeTracker;
