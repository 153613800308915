import React from 'react';
import './PopUpMessage.css';

const PopUpMessage = ({ message }) => {
    return (
        <div className = 'fade-box-container'>
            <p className='fade-box'>{message}</p>
        </div>
    )
}

export default PopUpMessage;