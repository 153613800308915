import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LongevityQuestionnaireResponse.css";
import axios from "axios";
import { getCookie } from "../../../utils/cookie";
import { fetchProbData } from "./action";

const baseUrl = process.env.REACT_APP_baseUrl;
function LongevityQuestionnaireResponse({ fullProfileData }) {
  // later, load a similar array from backend after Profile form completion
  let navigate = useNavigate();

  // const [showModal, setShowModal] = useState(false);
  const [probData, setProbData] = useState(null);

  // const recalculateInterventions = () => {
  //   console.log("recalculate interventions called");
  //   console.log("current reportData state: ", reportData);
  // };

  // const cancel = () => {
  //   console.log("cancel called");
  // };

  const handleSubmit = () => {
    // navigate("/intervention-selection")
  };

  const handleSelect = () => {
    navigate("/intervention-selection");
  };

  const fetchProb = async () => {
    try {
      const csrfToken = getCookie("csrftoken"); // Get the CSRF token from cookies
      const dataToSend = fullProfileData;
      const response = await axios.post(
        `${baseUrl}api/lifestyle/create-object-get-score-update/`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        }
      );
      console.log(response);
      setProbData(response.data);
    } catch (error) {
      console.error("Error saving data: ", error);
    }
  };

  // const asyncfetchProb = async (data) => {
  //   try {
  //     const prob = await fetchProbData(data);
  //     setProbData(prob);
  //   } catch (error) {
  //     console.error('Error submitting data:', error);
  //   }
  // };

  useEffect(() => {
    console.log(fullProfileData);
    // asyncfetchProb(fullProfileData);
    // console.log("responseData");
    // console.log(probData);
    fetchProb();
  }, []);

  return (
     (
      <div style={{ backgroundColor: "#F5F5F5", height: "100%"}}>
        {/* { reportData && console.log("reportData values: ", Object.values(reportData))} */}
        <div className="profile_sug_box">
          <div className="profile_sug_txt">
            <div>Sensitivity Report</div>
             <div>
             Here’s what we think is best for you! Start in one of the
              following categories and choose your preferred individual
              interventions
             </div>
             
    
          </div>
        </div>
        <div className="lgr_recommand_box">
          <div>
            *We recommend you show this personalized program to your doctor
            before beginning it and make any adjustments your doctor recommends.
          </div>
        </div>
        <div className="categories_box">
          <div className="categories_item" onClick={handleSelect}>
            <p className="categories_item_title">Sleep</p>
            <div className="categories_item_content">
              <p className="categories_item_content_left">
                There are 5 areas of sleep identified to help you feel better
                and more energized. Provide the rest of the summary describing
                the predicted health benefits and why they should do all the
                interventions if they can.
              </p>
              <p className="categories_item_content_right">0/5 Chosen</p>
            </div>
          </div>

          <div className="categories_item" onClick={handleSelect}>
            <p className="categories_item_title">Exercise</p>
            <div className="categories_item_content">
              <p className="categories_item_content_left">
                There are 10 areas of exercise identified to help you gain
                strength and increase mobility. These increases can strengthen
                bone and joint health to keep you up and active for longer.
                Allowing independence for long into your senior years.
              </p>
              <p className="categories_item_content_right">0/10 Chosen</p>
            </div>
          </div>

          <div className="categories_item" onClick={handleSelect}>
            <p className="categories_item_title">Diet</p>
            <div className="categories_item_content">
              <p className="categories_item_content_left">
                There are 9 areas of diet identified to help you lower blood
                pressure, and have more energy throughout the day. This can help
                improve almost every human function from skin, to energy levels,
                to sleep, mood regulation, the list goes on. This is one of the
                biggest factors in us helping you to maximize your longevity.
              </p>
              <p className="categories_item_content_right">4/9 Chosen</p>
            </div>
          </div>
        </div>
       
        <div className="current_prob">
          <p>
            Here’s your current probabilities given what you’re willing to do
          </p>
        </div>

        {
        probData&&<div className="lifespan_box">
          <div className="lifespan_estimate_title_box">
            <p className="lfe_title">Lifespan Estimate</p>
            <p className="lfe_number">90</p>
          </div>

          <div className="lifespan_estimate_content_box">
            <div className="lfe_disease_box">
              <p className="lfe_disease_title">Disease</p>
              <p>High Blood Pressure</p>
              <p>Arthritis
              </p>
              <p>
                Depression
              </p>
            </div>

            <div className="lfe_probability_box">
              <p className="lfe_disease_title">Probability</p>
              <p>Lower risk by {(parseFloat(probData.prob1)*100).toFixed(0)}%</p>
              <p>Lower risk by {(parseFloat(probData.prob2)*100).toFixed(0)}%</p>
              <p>Lower risk by {(parseFloat(probData.prob3)*100).toFixed(0)}%</p>
            </div>
          </div>
        </div>
        }

        <div className="refresh_cal_button" onClick={handleSubmit}>
          <span className="refresh_button_title">Refresh Calculations</span>
        </div>

        <div className="end_descripe">
          <p>
            If you’re satisfied with the plan and estimated health benefits, you
            can continue to the next page (You can always go back and edit your
            sensitivity report)
          </p>
        </div>
      </div>
    )
  );
}

export default LongevityQuestionnaireResponse;