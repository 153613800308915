const DATE_OF_BIRTH = "dob";                 // Corresponds to "dob"
const RACE = "race";                         // Corresponds to "race"
const GENDER = "gender";                     // Corresponds to "gender"
const WEIGHT = "weight";                     // Corresponds to "weight"
const HEIGHT = "height";                     // Corresponds to "height"
const EDUCATION = "highestEducationLevel";   // Corresponds to "highestEducationLevel"
const INCOME = "annualIncomeRange";          // Corresponds to "annualIncomeRange"
const WORKING_STATUS = "workStatus";         // Corresponds to "workStatus"
const MARITAL_STATUS = "maritalStatus";      // Corresponds to "maritalStatus"
const CIGARETTES_USE = "usedCigarettes";     // Corresponds to "usedCigarettes"

// For cigarettes years, smoking frequency, and alcohol drinks, there are no direct matches, but here are similar keys:
const FORMER_CIGARETTES_YEARS = "smokingDuration";        // Best guess for the duration the user has smoked
const CURRENT_CIGARETTES_YEARS = "smokingDuration";       // Same as above, as there is no specific key for current smokers
const CURRENT_CIGARETTES_PER_DAY = "smokingFrequency";    // Corresponds to "smokingFrequency"
const ALCOHOL_DRINKS_PER_WEEK = "numberOfAlcoholicDrink"; // Closest match is the number of alcoholic drinks, though it's not specified per week
export { DATE_OF_BIRTH, RACE, GENDER, WEIGHT, HEIGHT, EDUCATION, INCOME, WORKING_STATUS, MARITAL_STATUS, CIGARETTES_USE, FORMER_CIGARETTES_YEARS, CURRENT_CIGARETTES_YEARS, CURRENT_CIGARETTES_PER_DAY, ALCOHOL_DRINKS_PER_WEEK };