const processedMeat = 'meat_processed'
const redMeat = 'meat_unprocessed'
const poultry = 'meat_poultry'
const fish = 'meat_fish'
const fv = 'fruits_and_veggies'
const dairy = 'dairy'
const wholeGrain = 'grain_whole'
const refinedGrain = 'grain_refined'
const legumes = 'legumes'
const saturatedFat = 'fat_saturated'
const transFat = 'fat_trans'
const water = 'water'
const sugar = 'refined_sugar'
const sweetener = 'artificial_sweetener'
const alcohol = 'alcohol'
const cigarettes = 'cigarettes'
const calorieRestriction = 'calorie_restriction'
const fasting = 'fasting'


export { processedMeat, redMeat, poultry, fish, fv, dairy, wholeGrain, refinedGrain,
    legumes, saturatedFat, transFat, water, sugar, sweetener, alcohol, cigarettes,
    calorieRestriction, fasting }