const bedtimeName = "toBedTime";                   // Corresponds to "toBedTime"
const sleepOnsetName = "timeToSleep";              // Corresponds to "timeToSleep" (assumption: time taken to fall asleep)
const wakeUpTimeName = "getUpTime";                // Corresponds to "getUpTime"
const actualSleepHoursName = "timeActualSleep";    // Corresponds to "timeActualSleep" (assuming total hours of sleep)

const sleepQualityName = "sleepQuality";           // Corresponds to "sleepQuality"
const sleepMedicationName = "medicineFreq";        // Closest match might be "medicineFreq" if related to sleep medication usage
const stayAwakeTroubleName = "hadTroubleStayingAwake"; // Corresponds to "hadTroubleStayingAwake"
const enthusiasmLevelName = "problemToKeepEnthusiasm"; // Corresponds to "problemToKeepEnthusiasm"

const fallAsleepDifficultyName = "noAsleep";    // Reused "timeToSleep" if it refers to difficulty falling asleep quickly
const wakeUpMidnightName = "wakeUp";               // Corresponds to "wakeUp" (assumption: waking up in the middle of the night)
const bathroomNeedsName = "goBathroom";            // Corresponds to "goBathroom"
const breathingDifficultyName = "noBreathe";       // Corresponds to "noBreathe" (assumption: difficulty breathing)
const loudSnoringName = "snore";                   // Corresponds to "snore"
const coldFeetName = "tooCold";                    // Corresponds to "tooCold" (assuming it refers to feeling too cold)
const hotFeetName = "tooHot";                      // Corresponds to "tooHot" (assuming it refers to feeling too hot)
const badDreamsName = "badDreams";                 // Corresponds to "badDreams"
const painName = "pain";                           // Corresponds to "pain"

const sleepScore = "sleep_quality_score";

export {
    bedtimeName, sleepOnsetName, wakeUpTimeName, actualSleepHoursName,
    sleepQualityName, sleepMedicationName, stayAwakeTroubleName, enthusiasmLevelName,
    coldFeetName, hotFeetName, 

    fallAsleepDifficultyName, wakeUpMidnightName, bathroomNeedsName, breathingDifficultyName,
    loudSnoringName, badDreamsName, painName,

    sleepScore
};
