import React, {useMemo, useState, useEffect} from 'react'
import './Reports.css'
import { useAuthContext } from '../../hooks/useAuthContext'
import {Form} from 'react-bootstrap'
import Testchart from './report-components/Testchart'
import Sliderchart from './report-components/Sliderchart'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

function Reports() {

  const {user} = useAuthContext()

  const firstName = useMemo(() => {
      let user = localStorage.getItem("mysUser");
        user = user
          ? JSON.parse(user)
          : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
      return user.firstName;
  })

  const userId = useMemo(() => {
      let user = localStorage.getItem("mysUser");
          user = user
          ? JSON.parse(user)
          : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
      return user.userId;
  })

  return (
    <div style={{backgroundColor: "#F5F5F5"}}>
      <div className="questionnaire_title">Reports</div>
      <div className="cal_container">
        <Form>
          <div className="inlineContainer">
            Hi {firstName}, View your results and reports!
          </div>
          <Form.Group>
            <div className="inlineContainer">
              <Form.Text> Test Graphs</Form.Text>
              <p style={{color: "gray", fontSize: "14px"}}>(Slide to View More Graphs)</p>
            </div>
          </Form.Group>
          <Form.Group>
            <Sliderchart/>
          </Form.Group>
          <Form.Group style={{marginTop: '8rem'}}>
            <div className="inlineContainer">
              <Form.Text> Information Graphs</Form.Text>
              <p style={{color: "gray", fontSize: "14px"}}> (Slide to View More Graphs) </p>
            </div>
          </Form.Group>
          <Form.Group>
            <Testchart/>
          </Form.Group>
        </Form>
      </div>
    </div>
  )
}

export default Reports