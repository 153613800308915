import React, { useMemo, useState, useEffect, useRef } from 'react';
import './Plan.css';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { sendData } from './hooks/Data';
import { useAuthContext } from '../../hooks/useAuthContext';

const Diet = ({
  currDiet,
  setCurrDiet,
  optDiet,
  setOptDiet,
  newDiet,
  setNewDiet,
  waterUnit,
  waterLimit,
  weightUnit,
  loading,
  limit,
  setIsNewData,
  isNewData,
  toxicLevel,
  showModal,
  setShowModal,
}) => {
  const typingTimer = useRef(null);
  const typingInterval = 2000;

  const prevDiet = useRef({...currDiet});

  useEffect(() => {
    prevDiet.current = { ...currDiet };
  }, [currDiet]);


  const { user } = useAuthContext();
  const userId = useMemo(() => {
    let user = localStorage.getItem('mysUser');
    user = user
      ? JSON.parse(user)
      : { userId: '64ad93a2-a276-43e3-8304-be5fbd587ca1' };
    return user.userId;
  }, []);

  const fieldToSkip = ['fish', 'fat_saturated']

  // saved for future use if we decide to put RDA values.
  const tooltip = (limit, unit) => (
    // <Tooltip id='button-tooltip-2'>Warning: Greater than the maximum value of {limit}</Tooltip>
    <Tooltip id='button-tooltip-2'>Input value is too high, please validate your entry.</Tooltip>
  );

  const toxic_tooltip = (limit, unit) => (
    <Tooltip id='button-tooltip-2'>
      Values by {limit} {unit} are considered toxic. Please correct your
      entries.
    </Tooltip>
  );

  const handleChange = (e) => {
    let { name, value } = e.target;
    value = Number(value) < 0 ? 0 : Number(value);
    setNewDiet((prevState) => ({ ...prevState, [name]: value }));
    clearTimeout(typingTimer.current);

    typingTimer.current = setTimeout(() => {
      const prevValue = prevDiet.current[name];
      if (value === prevValue) {
        return;
      }
      handleSubmit(name, value);
      prevDiet.current = { ...prevDiet.current, [name]: value };
    }, typingInterval);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setNewDiet((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (name, value) => {
    const specialCharRegex = /[^0-9]?.[^0-9]/;
    if (specialCharRegex.test(Number(value))) {
      return;
    }
    if (value !== '') {
      if (value >= toxicLevel[name]) {
        setShowModal({show: true, name: name, value: value});
        // console.log('show modal');
      }
      else {
        // console.log('submit new data to backend');
        // console.log(name + ': ' + value);
        sendData({ [name]: value }, userId);
        if (!fieldToSkip.includes(name)) {
          setIsNewData(true);
        }
      }
    }
  };
  return (
    <section className='mt-4 bg bg-secondary p-3 bg-opacity-10 border border-secondary border-1 shadow'>
      <Form.Text>Diet</Form.Text>
      <div className='d-flex mb-1'>
        {/*Headers */}
        <div className='flex-grow-1 text-start'>
          <br />
        </div>
        <div className='inline heading-label' style={{ marginRight: '13px' }}>
          Current
        </div>
        <div className='inline heading-label' style={{ marginRight: '20px' }}>
          Optimal
        </div>
        <div className='inline heading-label' style={{ marginRight: '10px' }}>
          New
        </div>
      </div>

      {/*Meat */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Processed Meat
          </Form.Label>
          <div className='hover-supplement-text'>1 Serving ~ 3oz or 85g</div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>Servings / Week</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={currDiet.meat_processed === null ? '' : Math.round(currDiet.meat_processed)}
            disabled
            onChange={(e) =>
              setCurrDiet({ ...currDiet, meat_processed: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={optDiet.meat_processed === null ? '' : Math.round(optDiet.meat_processed)}
            disabled
            onChange={(e) =>
              setOptDiet({ ...optDiet, meat_processed: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(limit.meat_processed)}
            show={newDiet.meat_processed > limit.meat_processed && !isNewData}
          >
            <form onSubmit={(e) => e.preventDefault()}>
              <input
                type='text'
                className='plan-input text-center'
                required
                disabled={isNewData}
                value={newDiet.meat_processed}
                name='meat_processed'
                onChange={handleChange}
                onBlur={handleBlur}
                pattern='[0-9]*' // Only allows digits
                onKeyDown={(e) => {
                  if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                    e.preventDefault();
                  }
                }}
              />
            </form>
          </OverlayTrigger>
        </div>
      </div>

      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Red Meat
          </Form.Label>
          <div className='hover-supplement-text'>1 Serving ~ 3oz or 85g</div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>Servings / Week</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={currDiet.meat_unprocessed === null ? '' : Math.round(currDiet.meat_unprocessed)}
            disabled
            onChange={(e) =>
              setCurrDiet({ ...currDiet, meat_unprocessed: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={optDiet.meat_unprocessed === null ? '' : Math.round(optDiet.meat_unprocessed)}
            disabled
            onChange={(e) =>
              setOptDiet({ ...optDiet, meat_unprocessed: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(limit.meat_unprocessed)}
            show={newDiet.meat_unprocessed > limit.meat_unprocessed && !isNewData}
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              disabled={isNewData}
              value={newDiet.meat_unprocessed}
              name='meat_unprocessed'
              onChange={handleChange}
              onBlur={handleBlur}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Poultry
          </Form.Label>
          <div className='hover-supplement-text'>1 Serving ~ 3oz or 85g</div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>Servings / Week</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={currDiet.meat_poultry === null ? '' : Math.round(currDiet.meat_poultry)}
            disabled
            onChange={(e) =>
              setCurrDiet({ ...currDiet, meat_poultry: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={optDiet.meat_poultry === null ? '' : Math.round(optDiet.meat_poultry)}
            disabled
            onChange={(e) =>
              setOptDiet({ ...optDiet, meat_poultry: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(limit.meat_poultry)}
            show={newDiet.meat_poultry > limit.meat_poultry && !isNewData}
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              disabled={isNewData}
              value={newDiet.meat_poultry}
              name='meat_poultry'
              onChange={handleChange}
              onBlur={handleBlur}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Fish*
          </Form.Label>
          <div className='hover-supplement-text'>1 Serving ~ 3oz or 85g</div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>Servings / Week</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={currDiet.meat_fish === null ? '' : Math.round(currDiet.meat_fish)}
            disabled
            onChange={(e) =>
              setCurrDiet({ ...currDiet, meat_fish: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            // value={optDiet.meat_fish !== '' ? Math.round(optDiet.meat_fish) : ''}
            value={optDiet.meat_fish == null ? '' : Math.round(optDiet.meat_fish)}
            disabled
            onChange={(e) =>
              setOptDiet({ ...optDiet, meat_fish: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(limit.meat_fish)}
            show={newDiet.meat_fish > limit.meat_fish && !isNewData}
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              disabled={isNewData}
              value={newDiet.meat_fish}
              name='meat_fish'
              onChange={handleChange}
              onBlur={handleBlur}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/*Fruits and Veggies*/}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Fruits and Vegetables<span style={{ fontSize: '0.7em', verticalAlign: 'super' }}>!</span>
          </Form.Label>
          <div className='hover-supplement-text'>1 Serving ~ 1 cup or 100g</div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>Servings / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={currDiet.fruits_and_veggies === null ? '' : Math.round(currDiet.fruits_and_veggies)}
            disabled
            onChange={(e) =>
              setCurrDiet({ ...currDiet, fruits_and_veggies: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={optDiet.fruits_and_veggies === null ? '' : Math.round(optDiet.fruits_and_veggies)}
            disabled
            onChange={(e) =>
              setOptDiet({ ...optDiet, fruits_and_veggies: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(limit.fruits_and_veggies)}
            show={newDiet.fruits_and_veggies > limit.fruits_and_veggies && !isNewData}
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              disabled={isNewData}
              value={newDiet.fruits_and_veggies}
              name='fruits_and_veggies'
              onChange={handleChange}
              onBlur={handleBlur}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/*Dairy */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Dairy
          </Form.Label>
          <div className='hover-supplement-text'>
          1 serving is equivalent to 1 cup of milk/yogurt or 1-1.5 ounces of cheese
          </div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>Servings / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={currDiet.dairy === null ? '' : Math.round(currDiet.dairy)}
            disabled
            onChange={(e) =>
              setCurrDiet({ ...currDiet, dairy: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={optDiet.dairy === null ? '' : Math.round(optDiet.dairy)}
            disabled
            onChange={(e) => setOptDiet({ ...optDiet, dairy: e.target.value })}
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(limit.dairy)}
            show={newDiet.dairy > limit.dairy && !isNewData}
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              disabled={isNewData}
              value={newDiet.dairy}
              name='dairy'
              onChange={handleChange}
              onBlur={handleBlur}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/*Grains */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Whole Grains<span style={{ fontSize: '0.7em', verticalAlign: 'super' }}>!</span>
          </Form.Label>
          <div className='hover-supplement-text'>
            1 Serving ~ 1 slice bread or 1oz or 28g
          </div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>Servings / Week</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={currDiet.grain_whole === null ? '' : Math.round(currDiet.grain_whole)}
            disabled
            onChange={(e) =>
              setCurrDiet({ ...currDiet, grain_whole: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={optDiet.grain_whole === null ? '' : Math.round(optDiet.grain_whole)}
            disabled
            onChange={(e) =>
              setOptDiet({ ...optDiet, grain_whole: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(limit.grain_whole)}
            show={newDiet.grain_whole > limit.grain_whole && !isNewData}
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              disabled={isNewData}
              value={newDiet.grain_whole}
              name='grain_whole'
              onChange={handleChange}
              onBlur={handleBlur}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Refined Grains
          </Form.Label>
          <div className='hover-supplement-text'>
            1 Serving ~ 1 slice bread or 1oz or 28g
          </div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>Servings / Week</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={currDiet.grain_refined === null ? '' : Math.round(currDiet.grain_refined)}
            disabled
            onChange={(e) =>
              setCurrDiet({ ...currDiet, grain_refined: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={optDiet.grain_refined === null ? '' : Math.round(optDiet.grain_refined)}
            disabled
            onChange={(e) =>
              setOptDiet({ ...optDiet, grain_refined: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(limit.grain_refined)}
            show={newDiet.grain_refined > limit.grain_refined && !isNewData}
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              disabled={isNewData}
              value={newDiet.grain_refined}
              name='grain_refined'
              onChange={handleChange}
              onBlur={handleBlur}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/*Legumes */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Legumes<span style={{ fontSize: '0.7em', verticalAlign: 'super' }}>!</span>
          </Form.Label>
          <div className='hover-supplement-text'>
            1 Serving ~ 1/2 cup or 75g
          </div>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>Servings / Week</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={currDiet.legumes === null ? '' : Math.round(currDiet.legumes)}
            disabled
            onChange={(e) =>
              setCurrDiet({ ...currDiet, legumes: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={optDiet.legumes === null ? '' : Math.round(optDiet.legumes)}
            disabled
            onChange={(e) =>
              setOptDiet({ ...optDiet, legumes: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(limit.legumes)}
            show={newDiet.legumes > limit.legumes && !isNewData}
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              disabled={isNewData}
              value={newDiet.legumes}
              name='legumes'
              onChange={handleChange}
              onBlur={handleBlur}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/*Fats */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Saturated Fat*
          </Form.Label>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>g / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={currDiet.fat_saturated === null ? '' : Math.round(currDiet.fat_saturated)}
            disabled
            onChange={(e) =>
              setCurrDiet({ ...currDiet, fat_saturated: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={optDiet.fat_saturated == null ? '' : Math.round(optDiet.fat_saturated)}
            disabled
            onChange={(e) =>
              setOptDiet({ ...optDiet, fat_saturated: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(limit.fat_saturated)}
            show={newDiet.fat_saturated > limit.fat_saturated && !isNewData}
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              disabled={isNewData}
              value={newDiet.fat_saturated}
              name='fat_saturated'
              onChange={handleChange}
              onBlur={handleBlur}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Trans Fat
          </Form.Label>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>g / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={currDiet.fat_trans === null ? '' : Math.round(currDiet.fat_trans)}
            disabled
            onChange={(e) =>
              setCurrDiet({ ...currDiet, fat_trans: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={optDiet.fat_trans === null ? '' : Math.round(optDiet.fat_trans)}
            disabled
            onChange={(e) =>
              setOptDiet({ ...optDiet, fat_trans: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(limit.fat_trans)}
            show={newDiet.fat_trans > limit.fat_trans && !isNewData}
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              disabled={isNewData}
              value={newDiet.fat_trans}
              name='fat_trans'
              onChange={handleChange}
              onBlur={handleBlur}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/*Water */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Water in all forms
          </Form.Label>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>{waterUnit} / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={currDiet.water === null ? '' : Math.round(currDiet.water)}
            disabled
            onChange={(e) =>
              setCurrDiet({ ...currDiet, water: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={optDiet.water === null ? '' : Math.round(optDiet.water)}
            disabled
            onChange={(e) => setOptDiet({ ...optDiet, water: e.target.value })}
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={toxic_tooltip(toxicLevel.water, waterUnit)}
            show={newDiet.water > toxicLevel.water && !loading && !showModal.show}
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              onWheel={(e) => e.preventDefault()}
              disabled={isNewData}
              value={newDiet.water}
              name='water'
              onChange={handleChange}
              onBlur={handleBlur}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/*Refined Sugar and Sweetener*/}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Sugar
          </Form.Label>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>g / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={currDiet.refined_sugar === null ? '' : Math.round(currDiet.refined_sugar)}
            disabled
            onChange={(e) =>
              setCurrDiet({ ...currDiet, refined_sugar: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={optDiet.refined_sugar === null ? '' : Math.round(optDiet.refined_sugar)}
            disabled
            onChange={(e) =>
              setOptDiet({ ...optDiet, refined_sugar: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(limit.refined_sugar)}
            show={newDiet.refined_sugar > limit.refined_sugar && !isNewData}
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              disabled={isNewData}
              value={newDiet.refined_sugar}
              name='refined_sugar'
              onChange={handleChange}
              onBlur={handleBlur}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Artificial Sweetener
          </Form.Label>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>g / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={currDiet.artificial_sweetener === null ? '' : Math.round(currDiet.artificial_sweetener)}
            defaultValue=''
            disabled
            onChange={(e) =>
              setCurrDiet({
                ...currDiet,
                artificial_sweetener: e.target.value,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={optDiet.artificial_sweetener === null ? '' : Math.round(optDiet.artificial_sweetener)}
            disabled
            onChange={(e) =>
              setOptDiet({ ...optDiet, artificial_sweetener: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(limit.artificial_sweetener)}
            show={newDiet.artificial_sweetener > limit.artificial_sweetener && !isNewData}
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              disabled={isNewData}
              value={newDiet.artificial_sweetener}
              name='artificial_sweetener'
              onChange={handleChange}
              onBlur={handleBlur}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/* Alcohol */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Alcohol
          </Form.Label>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>Drinks / Week</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={currDiet.alcohol === null ? '' : Math.round(currDiet.alcohol)}
            disabled
            onChange={(e) =>
              setCurrDiet({ ...currDiet, alcohol: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={optDiet.alcohol === null ? '' : Math.round(optDiet.alcohol)}
            disabled
            onChange={(e) =>
              setOptDiet({ ...optDiet, alcohol: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(limit.alcohol)}
            show={newDiet.alcohol > limit.alcohol && !isNewData}
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              disabled={isNewData}
              value={newDiet.alcohol}
              name='alcohol'
              onChange={handleChange}
              onBlur={handleBlur}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/* Cigarettes */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Cigarettes
          </Form.Label>
        </div>
        <div className='form-inline'>
          <p className='mx-2 mt-2 exercise-label'>Cigarettes / Day</p>
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={currDiet.cigarettes === null ? '' : Math.round(currDiet.cigarettes)}
            disabled
            onChange={(e) =>
              setCurrDiet({ ...currDiet, cigarettes: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='text'
            className='plan-input me-4 text-center'
            required
            value={optDiet.cigarettes === null ? '' : Math.round(optDiet.cigarettes)}
            disabled
            onChange={(e) =>
              setOptDiet({ ...setOptDiet, cigarettes: e.target.value })
            }
          />
        </div>
        <div className='form-inline'>
          <OverlayTrigger
            placement='right'
            overlay={tooltip(limit.cigarettes)}
            show={newDiet.cigarettes > limit.cigarettes && !isNewData}
          >
            <input
              type='text'
              className='plan-input text-center'
              required
              disabled={isNewData}
              value={newDiet.cigarettes}
              name='cigarettes'
              onChange={handleChange}
              onBlur={handleBlur}
              pattern='[0-9]*' // Only allows digits
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                  e.preventDefault();
                }
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      {/* Calorite Restriction and Fasting */}
      <div className='d-flex mb-2'>
        <div className='flex-grow-1 text-start me-1 hover-position'>
          <Form.Label className='label-font hover-supplement '>
            Calorie Restriction
          </Form.Label>
        </div>
        <div className='form-inline'>
          <input
            type='checkbox'
            checked={currDiet.calorie_restriction}
            className='supplement-box supplement-box-margin'
            disabled
            onChange={() =>
              setCurrDiet({
                ...currDiet,
                calorie_restriction: !currDiet.calorie_restriction,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='checkbox'
            checked={optDiet.calorie_restriction}
            className='supplement-box supplement-box-margin'
            disabled
            onChange={() =>
              setOptDiet({
                ...optDiet,
                calorie_restriction: !optDiet.calorie_restriction,
              })
            }
          />
        </div>
        <div className='form-inline'>
          <input
            type='checkbox'
            disabled={isNewData}
            checked={newDiet.calorie_restriction}
            className='supplement-box me-2'
            onChange={() => {
              setNewDiet({
                ...newDiet,
                calorie_restriction: !newDiet.calorie_restriction,
              });
              sendData(
                { calorie_restriction: !newDiet.calorie_restriction },
                userId
              );
              setIsNewData(true);
            }}
          />
        </div>
      </div>
      {/* // displayed unused things here */}
      {/* <div className="d-flex mb-2">
          <div className="flex-grow-1 text-start me-1 hover-position">
              <Form.Label className="label-font hover-supplement ">
                  Fasting
              </Form.Label>
              </div>
              <div className="form-inline">
              <input
                  type="checkbox"
                  checked={currDiet.fasting}
                  className="supplement-box supplement-box-margin"
                  disabled
                  onChange={() =>
                  setCurrDiet({
                      ...currDiet,
                      fasting: !currDiet.fasting
                  })
                  }
              />
              </div>
              <div className="form-inline">
              <input
                  type="checkbox"
                  checked={optDiet.fasting}
                  className="supplement-box supplement-box-margin"
                  disabled
                  onChange={() => 
                  setOptDiet({
                      ...optDiet,
                      fasting: !optDiet.fasting
                  })
                  }
              />
              </div>
              <div className="form-inline">
              <input
                  type="checkbox"
                  checked={newDiet.fasting}
                  className="supplement-box me-2"
                  onChange={() => {
                  setNewDiet({
                      ...newDiet,
                      fasting: !newDiet.fasting
                  });
                  sendData({fasting: !newDiet.fasting}, userId)
                  }}
              />
              </div>
          </div> */}
    </section>
  );
};

export default Diet;
