// const fiberOptions = ["g/day", "mg/day"];
const fiberOptions = ["g/day"];
const proteinOptions = ["g/day"];
const magnesiumOptions = ["mg/day"];
const manganeseOptions = ["mg/day"];
const phosphorusOptions = ["mg/day"];
const potassiumOptions = ["mg/day"];
const vitaminAOptions = ["ug/day"];
const vitaminCOptions = ["mg/day"];
const vitaminDOptions = ["ug/day"];
const vitaminKOptions = ["ug/day"];
const biotinOptions = ["ug/day"];
const chlorideOptions = ["mg/day"];
const chromiumOptions = ["ug/day"];
const copperOptions = ["mg/day"];
const folateFolicAcidOptions = ["ug/day"];
const molybdenumOptions = ["ug/day"];
const niacinOptions = ["mg/day"];
const pantothenicAcidOptions = ["mg/day"];
const riboflavinOptions = ["mg/day"];
const seleniumOptions = ["ug/day"];
const thiamineOptions = ["mg/day"];
const cholineOptions = ["mg/day"];
const vitaminB6Options = ["mg/day"];
const vitaminB12Options = ["ug/day"];
// const vitaminEOptions = ["mg/day", "IU/day"];
const vitaminEOptions = ["mg/day"];
const zincOptions = ["mg/day"];
const iodineOptions = ["ug/day"];
const ironOptions = ["mg/day"];
const nickelOptions = ["ug/day"];
const fishOilOmega3Options = ["mg/day"];
const melatoninOptions = ["mg/day"];
const turmericOptions = ["mg/day"];
const garlicOptions = ["mg/day"];
// const matchaGreenTeaOptions = ["ml/day", "oz/day"];
const matchaGreenTeaOptions = ["oz/day"];
const plantSterolsOptions = ["mg/day"];
const nmnOptions = ["mg/day"];
const nrOptions = ["mg/day"];
const calciumAKGOptions = ["mg/day"];
const alphaLipoicAcidOptions = ["mg/day"];
const quercetinOptions = ["mg/day"];
const pqqOptions = ["mg/day"];
const hyaluronicAcidOptions = ["mg/day"];
const chlorellaOptions = ["mg/day"];
const spirulinaOptions = ["mg/day"];
const reveratrolOptions = ["mg/day"];
// const coQ10Options = ["mg/day", "g/day"];
const coQ10Options = ["mg/day"];
// const acetylLCarnitineOptions = ["mg/day", "g/day"];
const acetylLCarnitineOptions = ["mg/day"];
const oliveOilOptions = ["g/day"];
const luteinOptions = ["mg/day"];
const milkThistleOptions = ["mg/day"];
const spermidineOptions = ["mg/day"];
const creatineOptions = ["g/day"];
// const trimethylGlycineOptions = ["mg/day", "g/day"];
const trimethylGlycineOptions = ["mg/day"];
// const calciumOptions = ["mg/day", "g/day"];
const calciumOptions = ["mg/day"];
const supplementsOptions = ["Multi-vitamin", "Probiotics", "Collagen Peptides"]

export {
    fiberOptions, proteinOptions, magnesiumOptions, manganeseOptions, phosphorusOptions, potassiumOptions, vitaminAOptions, vitaminCOptions, vitaminDOptions, vitaminKOptions,
    biotinOptions, chlorideOptions, chromiumOptions, copperOptions, folateFolicAcidOptions, molybdenumOptions, niacinOptions, pantothenicAcidOptions, riboflavinOptions, seleniumOptions,
    thiamineOptions, cholineOptions, vitaminB6Options, vitaminB12Options, vitaminEOptions, zincOptions, iodineOptions, ironOptions, nickelOptions, fishOilOmega3Options,
    melatoninOptions, turmericOptions, garlicOptions, matchaGreenTeaOptions, plantSterolsOptions, nmnOptions, nrOptions, calciumAKGOptions, alphaLipoicAcidOptions, quercetinOptions,
    pqqOptions, hyaluronicAcidOptions, chlorellaOptions, spirulinaOptions, reveratrolOptions, coQ10Options, acetylLCarnitineOptions, oliveOilOptions, luteinOptions, milkThistleOptions,
    spermidineOptions, creatineOptions, trimethylGlycineOptions, calciumOptions, supplementsOptions
};
