import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios'

import "./Organizations.css";

const OrgInvitePage = () => {
  //userid
  const userJSON = localStorage.getItem("mysUser");
  const user = JSON.parse(userJSON)
  const userid = user.userId

  //api
  const baseurl = process.env.REACT_APP_baseUrl
  const accepturl = 'api/organizations/accept-invite'

  //search params (?x=abc) 
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  //invitation parameter
  const invite_id = queryParams.get('invite_id')
  const orgtype = queryParams.get('orgtype')

  const navigate = useNavigate();

  const [acceptFail, setAcceptFail] = useState(false);
  const [acceptSuccess, setAcceptSuccess] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


  const fetchPremiumStatus = async (id) => {
    const response = await fetch(`${baseurl}api/users/userpremium/${id}`);
    const data = await response.json();
    const premiumVal = data.userPremiumLevel !== 0;
    return premiumVal;
  };
  const updateIsPremium = async () => {
    setIsButtonDisabled(true);
    const userStr = localStorage.getItem("mysUser");
    if (userStr) {
      const userObj = JSON.parse(userStr);
      const newIsPremium = await fetchPremiumStatus(userObj.userId);
      userObj.isPremium = newIsPremium;
      localStorage.setItem("mysUser", JSON.stringify(userObj));
    }
    navigate("/", { replace: true });
    window.location.reload();
  };
  
  const handleAcceptInvitation = () => {
    // Logic to accept the invitation
    axios.post(baseurl + accepturl, {
      "user_id" : userid,
      "invite_id" : invite_id,
    })
    .then(_ => {
      setAcceptSuccess(true);
      setTimeout(() => {
        updateIsPremium()
      }, 2000);
    })
    .catch(error => {
      setAcceptFail(true);
      console.error('Error inviting member: ', error)
    })
  };

  return (
    <div>
        <div>
          {acceptFail ? (
            <div className='accept-invite-container'>
              <h4>Failed to accept the invitation. Please contact your {orgtype === 'C' ? 'organization' : 'family'} manager.</h4>
            </div>
          ) : (
            !acceptSuccess ? (
              <div className='accept-invite-container'>
                <h1>You've been invited to {orgtype === 'C' ? 'an organization' : 'a family'}!</h1>
                <button className='accept-invite-btn' onClick={handleAcceptInvitation} disabled={isButtonDisabled}>Accept Invitation</button>
              </div>
            ) : (
              <div className='accept-invite-container'>
                <h4>Success! You will be redirected to the homepage shortly...</h4>
              </div>
            )
          )}
        </div>
    </div>
  );
};
export default OrgInvitePage;