import axios from 'axios'
import {getCookie} from "../../utils/cookie"
const baseUrl = process.env.REACT_APP_baseUrl;
const baseUrlAWS =`${baseUrl}api/log`

const baseUrlUser = `${baseUrl}api/users`

export const getLoggedData = async (userId, date) => {
    const csrfToken = getCookie("csrftoken")
    return axios.get(
        `${baseUrlAWS}/get-logged-data/${userId}/${date}`,
        {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken,
            },
        })
        .then(response => response)
        .catch(error => {
            if (error.response && error.response.status === 500) {
                return null
            } else {
                console.error('Error fetching form data:', error)
                throw error
            }
        })
}



// call API to submit the form data 
export const submitData =  async (formData) => {
    const csrfToken = getCookie("csrftoken")
    console.log(JSON.stringify(formData))
    axios.post(
        `${baseUrlAWS}/log-response/`,
        formData,
        {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken,
            },
        })
        .then(response => {
            console.log(response.data)
        })
        .catch(error => {
            if (error.response && error.response.status === 500) {
                return null
            } else {
                console.error('Error fetching form data:', error)
                throw error
            }
        })
}

//call API to retrieve log dates
export const fetchDates = (userId) => {
    return axios.get(
        `${baseUrlAWS}/get-logged-dates/${userId}`,
        {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(response => response)
        .catch(error => {
            if (error.response && error.response.status === 500) {
                return null
            } else {
                console.error('Error fetching form data:', error)
                throw error
            }
        })

}

//fetch user data
export const fetchUserData = (userId) => {
    return axios.get(
        `${baseUrlUser}/get-user-data/${userId}`,
        {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(response => response.data)
        .catch(error => {
            if (error.resposne && error.response.status === 500) {
                return null
            } else {
                console.error('Error fetching form data:', error)
                throw error
            }
        })

}