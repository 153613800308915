import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";
import "./global.css";
import ReactGA from 'react-ga4';

// Inject Google Analytics
const TRACKING_ID = "G-LFL6H2WH7G"; 
ReactGA.initialize(TRACKING_ID);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  //   <AuthContextProvider>
  //     <App />
  //   </AuthContextProvider>
  // </React.StrictMode>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
);
