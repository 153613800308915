const genderLabel = "What is your gender?";
const dateOfBirthLabel = "What is your date of birth?";
const raceLabel = "What is your race?";
const weightLabel = "Weight";
const heightLabel = "Height";
const educationLabel = "Please select your highest level of education completed";
const incomeLabel = "Please select your annual income range";
const workingStatusLabel = "Are you working or not working?";
const maritalStatusLabel = "What is your marital status?";
const cigarettesUseLabel = "Have you ever used cigarettes?";
const formerCigarettesYearsLabel = "How many years did you use cigarettes?";
const currentCigarettesYearsLabel = "How many years have you used cigarettes?";
const currentCigarettesPerDayLabel = "How many cigarettes do you smoke a day?";
const alcoholDrinksPerWeekLabel = "Please input the average number of alcoholic drinks per week you consume";

export { genderLabel, dateOfBirthLabel, raceLabel, weightLabel, heightLabel, educationLabel, incomeLabel, workingStatusLabel, maritalStatusLabel, cigarettesUseLabel, formerCigarettesYearsLabel, currentCigarettesYearsLabel, currentCigarettesPerDayLabel, alcoholDrinksPerWeekLabel };

